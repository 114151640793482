import { Abi, Log, TransactionReceipt, decodeEventLog } from "viem";

export const decodeReceiptLogs = ({ abi, receipt, eventName }: {
    abi: Abi,
    receipt: TransactionReceipt,
    eventName: string
}): any => {
    const logs = receipt.logs.map(log => {
        return decodeEventLog({
            abi: abi,
            data: log.data,
            topics: log.topics
        });
    }).find((log) => log.eventName === eventName);

    return logs?.args;
};

export const decodeReceiptLog = (
    abi: Abi,
    log: Log,
): any => {
    const res = decodeEventLog({
        abi: abi,
        data: log.data,
        topics: log.topics
    });

    return res?.args;
};