import { ENVS } from '@/constants/config';
import { ReportItem } from '@/types/proof';
import { dateFormat } from '@/utils/common';
import { Badge, Button } from '@ethsign/ui';
import { Link, useNavigate } from 'react-router-dom';

type Props = ReportItem;

export const ReportCard = (props: Props) => {
  const { name, totalValue, reportDate, attestationId, id } = props;
  const navigate = useNavigate();
  return (
    <div className="w-[310px] rounded-[20px] bg-white p-5 shadow-report-card">
      <div className="flex w-full gap-4">
        <img
          crossOrigin="anonymous"
          className="h-10 w-8"
          src="https://sign-public-cdn.s3.us-east-1.amazonaws.com/PoF/report-icon_240724085551.webp"
        ></img>
        <div className="min-w-1 flex-1">
          <div className="mb-4 truncate font-bold text-md text-[#373B46]" title={name}>
            {name}
          </div>
          <div className="break-all font-medium text-xs text-[#373B46]">
            <span className=" text-[#848CA1]">Asset Amount: </span>${Number(totalValue).toFixed(2)}
          </div>
          <div className="mt-2 font-medium text-xs text-[#373B46]">
            <span className="text-[#848CA1]">Created on: </span>
            {dateFormat(Number(reportDate), 'date')}
          </div>
        </div>
      </div>
      <div className="mt-8 flex justify-between gap-4">
        {attestationId ? (
          <Button
            className="h-8 w-[133px] text-[12px]/[16px]"
            size="sm"
            variant="outline"
            disabled={!attestationId}
            onClick={() => {
              window.open(
                (ENVS.ENV === 'dev'
                  ? 'http://testnet-scan.sign.global/attestation/'
                  : 'https://scan.sign.global/attestation/') + attestationId
              );
            }}
          >
            View Attestation
          </Button>
        ) : (
          <Badge className="select-none bg-gray-100 text-secondary-foreground hover:bg-gray-100">unattested</Badge>
        )}
        <Link to={`/report/${id}`}>
          <Button className="h-8 w-[133px] text-[12px]/[16px]" size="sm">
            View Report
          </Button>
        </Link>
      </div>
    </div>
  );
};
