import { Loader2 } from 'lucide-react';
import { ReactNode, useEffect, useState } from 'react';
import { EventEmitter } from 'events';

const eventBus = new EventEmitter();

export function Loading() {
  return (
    <div className="absolute inset-0  z-[99] flex flex-col items-center justify-center bg-background/80">
      <Loader2 className="animate-spin" color="#0052FF" size={32} />
    </div>
  );
}

export const Spin = ({ children, loading }: { children: ReactNode; loading: boolean }) => {
  return (
    <div className="relative size-full">
      {loading && (
        <div className={'absolute left-0 top-0 z-[9999] flex size-full items-center justify-center bg-background/80'}>
          <Loader2 className="animate-spin" color="#CF5C10" size={32} />
        </div>
      )}
      <div className="relative h-full">{children}</div>
    </div>
  );
};

export const GlobalLoading = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const cb = (loading: boolean) => {
      setLoading(loading);
    };

    eventBus.on('loading', cb);

    return () => {
      eventBus.off('loading', cb);
    };
  }, []);
  return loading ? <Loading /> : null;
};

// eslint-disable-next-line react-refresh/only-export-components
export const showGlobalLoading = () => {
  eventBus.emit('loading', true);
};

// eslint-disable-next-line react-refresh/only-export-components
export const hideGlobalLoading = () => {
  eventBus.emit('loading', false);
};
