import { useCurrentUser } from '@/components/UserProvider';
import { ButtonRadio } from '@/components/base/ButtonRadio';
import { OnChainDataItem } from '@/services/proof';
import { CurrencyBitcoin } from '@ethsign/icons';
import { shortenWalletAddress } from '@ethsign/utils-web';
import React, { useState } from 'react';
import { CoinTypeModal } from './CoinTypeModal';
import { EmptyView } from './EmptyView';
import { OnchainAssetCard } from './OnchainAssetCard';

interface OnChainViewProps {
  data: OnChainDataItem[];
}

export const OnchainView: React.FC<OnChainViewProps> = (props: OnChainViewProps) => {
  const { data } = props;

  const { user } = useCurrentUser();

  const [coinTypeModalVisible, setCoinTypeModalVisible] = useState(false);

  const [currentWallet, setCurrentWallet] = useState('all');

  const isEmpty = !(data?.length > 0);

  const currentWalletData = data.filter((wallet) =>
    currentWallet === 'all' ? true : wallet.address === currentWallet
  );

  return (
    <>
      {isEmpty && (
        <EmptyView
          icon={<CurrencyBitcoin color="hsl(var(--primary))" size={24} />}
          title="No Onchain Asset"
          description=" There is no onchain asset connected. Add your onchain asset through the add asset button on the top left corner."
        />
      )}

      {data.length > 1 && (
        <ButtonRadio
          value={currentWallet}
          onChange={(value) => {
            setCurrentWallet(value);
          }}
          options={[
            { label: 'View all', value: 'all' },
            ...data.map((wallet) => ({
              label: shortenWalletAddress(wallet.address, 'shorter'),
              value: wallet.address,
              tag: user?.userId === wallet.address ? 'main' : undefined
            }))
          ]}
        />
      )}

      {currentWalletData.map((wallet) => (
        <OnchainAssetCard key={wallet.address} wallet={wallet} />
      ))}

      <CoinTypeModal open={coinTypeModalVisible} onOpenChange={(visible) => setCoinTypeModalVisible(visible)} />
    </>
  );
};
