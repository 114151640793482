import { getChainById } from '@/constants/chain-config';
import { Button } from '@ethsign/ui';
import { createWalletClient, custom } from 'viem';
import { useAccount, useSwitchChain } from 'wagmi';

interface INetworkButtonProps extends React.ComponentProps<typeof Button> {
  targetChainId?: number | string;
}

const NOT_CONFIGURED_ERROR = 'not configured';

// use clickFunctioin instead of onClick to skip type check
export const NetworkCheckButton = ({ targetChainId, onClick, ...rest }: INetworkButtonProps) => {
  const {chain} = useAccount()

  const { switchChainAsync } = useSwitchChain()

  const checkNetworkBeforeClick = async (e: any) => {
    if (chain?.id && targetChainId) {
      if (targetChainId)
        if (`${chain?.id}` !== `${targetChainId}` && switchChainAsync) {
          try {
            await switchChainAsync({chainId:targetChainId as number});
          } catch (e) {
            console.log('e', e);
            if ((e as any)?.message.includes(NOT_CONFIGURED_ERROR) && !(e as any)?.message.includes('User rejected')) {
      
              const walletClient = createWalletClient({
                chain: chain,
                transport: custom(window.ethereum!)
              });
              const addChain = getChainById(targetChainId as number);
              walletClient.addChain({ chain:addChain });
              // await window?.ethereum?.request({
              //   method: 'wallet_addEthereumChain' as any,
              //   params: [
              //     {
              //       chainId: numberToHex(id),
              //       chainName: name,
              //       nativeCurrency,
              //       rpcUrls: rpcUrls.default.http,
              //       blockExplorerUrls: [blockExplorers?.default.url ?? blockExplorers?.etherscan?.url]
              //     }
              //   ] as any
              // });
            }
          }
          return;
        }
    }
    onClick?.(e);
  };

  return <Button onClick={checkNetworkBeforeClick} {...rest} />;
};
