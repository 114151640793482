import { Constants } from '@/constants';
import { useAxiomCircuit } from '@axiom-crypto/react';
import { Button } from '@ethsign/ui';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { decodeEventLog, formatEther, formatUnits } from 'viem';
import {
  useAccount,
  useSimulateContract,
  useWaitForTransactionReceipt,
  useWatchContractEvent,
  useWriteContract
} from 'wagmi';

import Decimals from '../Decimals';
import { simulateContract, waitForTransactionReceipt, writeContract } from '@wagmi/core';
import { wagmiConfig } from '@/Web3Provider';
import { decodeReceiptLog, decodeReceiptLogs } from '@/utils/chain';
import { NetworkCheckButton } from '../Button/NetworkCheckButton';
import { sepolia } from 'viem/chains';

export default function SubmitQuery({ callbackAbi }: { callbackAbi: any[] }) {
  const navigate = useNavigate();
  const { chain } = useAccount();
  const { builtQuery } = useAxiomCircuit();
  const [log, setLog] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [queryData, setQueryData] = useState<any>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  // Prepare hook for the sendQuery transaction
  // Note: builtQuery should not be null because we check this in BuildQuery.tsx

  const submitQuery = async () => {
    console.log('submitQuery');
    setLoading(true);

    const tx = await writeContract(wagmiConfig, {
      address: builtQuery?.address as `0x${string}`,
      abi: builtQuery!.abi,
      functionName: builtQuery!.functionName,
      value: builtQuery!.value,
      args: builtQuery!.args
    });
    setIsSuccess(true);
    console.log('request', tx);
    const res = await waitForTransactionReceipt(wagmiConfig, { hash: tx });
    console.log(res);
    setQueryData(res);

    const log = decodeReceiptLogs({
      abi: callbackAbi,
      receipt: res,
      eventName: 'AxiomV2Call'
    });
    console.log(log, 'AxiomV2Call');
  };

  // const { data } = useSimulateContract({
  //   address: builtQuery?.address as `0x${string}`,
  //   abi: builtQuery!.abi,
  //   functionName: builtQuery!.functionName,
  //   value: builtQuery!.value,
  //   args: builtQuery!.args,
  // });

  // const { writeContract, isSuccess, isPending, data: tx } = useWriteContract();

  // const { data: queryData } = useWaitForTransactionReceipt({ hash: tx });

  console.log(queryData, builtQuery, chain, 'hh');

  // Monitor contract for `AxiomV2Call` event
  useWatchContractEvent({
    address: Constants[chain?.id as any].CALLBACK_CONTRACT as `0x${string}`,
    abi: callbackAbi,
    eventName: 'AverageBalanceStored',
    chainId: chain?.id,
    onLogs: (logs) => {
      const decodeLog = decodeReceiptLog(callbackAbi, logs[0]);
      console.log(logs, decodeLog, 'log');
      setLog(decodeLog);
      const topics = logs[0].topics;
      // check that the queryId is the same as the one we just sent
      if (topics[3] && builtQuery?.queryId && BigInt(topics[3]) === BigInt(builtQuery?.queryId)) {
        const txHash = logs[0].transactionHash;
        console.log(txHash, 'txHash');
        // navigate(`success/?txHash=${txHash}`);
      }
    },
    onError: (error) => {
      console.error('error', error);
    }
  });

  const renderButtonText = () => {
    if (isSuccess) {
      return 'Waiting for callback...';
    }
    if (loading) {
      return 'Confirm transaction in wallet...';
    }
    return 'Submit query';
  };

  const renderClaimProofCostText = () => {
    return (
      <div className="flex flex-col items-center text-xs text-midtone mt-2">
        <div>
          {'Generating the proof for this query costs up to '}
          <Decimals>{formatEther(BigInt(builtQuery?.value ?? 0)).toString()}</Decimals>
          {' ETH'}
        </div>
        <div>
          {'(Based on a current maxFeePerGas of '}
          <Decimals>{formatUnits(builtQuery?.args?.[4]?.maxFeePerGas ?? '0', 9).toString()}</Decimals>
          {' gwei)'}
        </div>
      </div>
    );
  };

  const renderExplorerLink = () => {
    if (!queryData) {
      return null;
    }
    return (
      <Link to={`${Constants[chain!.id].EXPLORER_BASE_URL}/query/${builtQuery?.queryId}`} target="_blank">
        View status on Axiom Explorer
      </Link>
    );
  };

  return (
    <div className="flex flex-col items-center gap-2">
      <NetworkCheckButton onClick={() => submitQuery()} loading={loading} targetChainId={sepolia.id}>
        <span>{renderButtonText()}</span>
      </NetworkCheckButton>
      <div>
        {log ? (
          <div>
            <div>user's average account value (ETH) over 8 evenly spaced blocks in the past 24 hours: </div>
            <div>
              {log.addr}: {formatUnits(log.averageBalance, 18)} ETH
            </div>
          </div>
        ) : null}
      </div>
      <div className="flex flex-col items-center text-sm gap-2">
        <div>{isSuccess ? 'Proof generation may take up to 3 minutes' : renderClaimProofCostText()}</div>
        {renderExplorerLink()}
      </div>
    </div>
  );
}
