import AxiomProvider from '@/AxiomProvider';
import { useCurrentUser } from '@/components/UserProvider';
import { useViewModel } from '@/utils/bizify';
import { handleAmount } from '@/utils/common';
import { Atom01 } from '@ethsign/icons';
import { Button, Tabs } from '@ethsign/ui';
import { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageCtrl } from './PageCtrl';
import { AssetsView } from './components/AssetsView';
import { GenerateReportModal } from './components/GenerateReportModal';
import { ReportsView } from './components/ReportsView';

export type ChainType = 'onChain' | 'offChain';

enum ViewType {
  Asset = 'asset',
  Report = 'report'
}

export default function Dashboard() {
  const navigate = useNavigate();
  const vm = useViewModel(PageCtrl);
  const vmData = vm.$useSnapshot();
  const { user } = useCurrentUser();

  useEffect(() => {
    user?.userId && vm.queryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.userId]);

  const [searchParams, setSearchParams] = useSearchParams();

  const currentView = useMemo(() => {
    const viewParam = searchParams.get('view');
    if (!viewParam || viewParam === ViewType.Asset) return ViewType.Asset;
    return ViewType.Report;
  }, [searchParams]);

  const setCurrentView = (viewType: ViewType) => {
    setSearchParams((oldValue) => {
      oldValue.set('view', viewType)
      return oldValue;
    });
  };

  return (
    <div className="h-full">
      <div className="container py-10 ">
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-[6px]">
            <span className="font-bold text-[38px] leading-[50px] text-black">
              ${handleAmount(vmData.totalAmount || 0)}
            </span>

            <span className="text-[13px] font-normal leading-[18px] text-gray-600">Total Assets</span>
          </div>

          <div className="flex gap-4">
            <Button
              disabled={!user?.userId}
              variant="secondary"
              className="bg-secondary text-secondary-foreground hover:bg-secondary-100 hover:text-secondary-foreground"
              onClick={async () => {
                vm.setData({ generateReportModalOpen: true });
              }}
            >
              <Atom01 color="hsl(var(--secondary-foreground))" size={20} className="mr-2" />
              Generate Report
            </Button>
          </div>
        </div>

        <Tabs
          value={currentView}
          variant="underline"
          className="mt-8 w-full justify-start border-b border-b-gray-300 bg-transparent [&>button[data-state='active']]:!border-primary [&>button[data-state='active']]:text-primary [&>button[data-state='inactive']]:!border-transparent [&>button]:flex-none [&>button]:!bg-transparent"
          items={[
            {
              label: 'My Assets',
              value: ViewType.Asset,
              content: (
                <AssetsView onchainBalanceList={vmData.balanceList} offchainBalanceList={vmData.offchainBalanceList} />
              )
            },
            { label: 'Reports', value: ViewType.Report, content: <ReportsView data={vmData.reportList} /> }
          ]}
          onValueChange={(value) => {
            setCurrentView(value as ViewType)
          }}
        />
      </div>
      <AxiomProvider>
        <GenerateReportModal
          open={vmData.generateReportModalOpen}
          onOpenChange={(open) => {
            vm.setData({
              generateReportModalOpen: open
            });
          }}
          onChainAssets={vmData.balanceList}
          offChainAssets={vmData.offchainBalanceList}
          onGenerate={async (reportAccouts) => {
            const reportId = await vm.generateReport(reportAccouts);
            navigate('/report/' + reportId);
          }}
        />
      </AxiomProvider>
    </div>
  );
}
