import { plaidPrepare } from '@/services';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';

interface PlaidConnectorProps {
  token: string;
  onError?: (error: ErrorEvent | null) => void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onReady?: (context: { open: Function; exit: Function }) => void;
  onSuccess?: PlaidLinkOptions['onSuccess'];
  onExit?: PlaidLinkOptions['onExit'];
  onEvent?: PlaidLinkOptions['onEvent'];
}

const noop = () => {};

export const PlaidLinkConnector: React.FC<PropsWithChildren<PlaidConnectorProps>> = (props) => {
  const { token, children, onError, onReady, onSuccess = noop, onExit = noop, onEvent = noop } = props;

  const { error, exit, open, ready } = usePlaidLink({ token, onSuccess, onExit, onEvent });

  useEffect(() => {
    if (ready) onReady?.({ open, exit });
    if (error) onError?.(error);
  }, [ready, error, onReady, open, onError, exit]);

  return <>{children}</>;
};

// eslint-disable-next-line react-refresh/only-export-components
export const usePlaidConnector = (
  props: Pick<PlaidConnectorProps, 'onError' | 'onSuccess' | 'onReady' | 'onExit' | 'onEvent'>
) => {
  const { onError, onReady, onSuccess, onExit, onEvent } = props;

  const [ready, setReady] = useState(true);
  const [token, setToken] = useState(null);
  const [isCreatingToken, setIsCreatingToken] = useState(false);

  const generateToken = async () => {
    setIsCreatingToken(true);

    const data = await plaidPrepare();

    setIsCreatingToken(false);
    return data.link_token;
  };

  const connectPlaid = async () => {
    setReady(false);
    const newToken = await generateToken();
    setToken(newToken);
  };

  const connectorNode = useMemo(() => {
    if (!token) return '';

    return (
      <PlaidLinkConnector
        token={token}
        onError={onError}
        onReady={(context) => {
          setReady(true);
          onReady?.(context);
        }}
        onSuccess={(context, metadata) => {
          setToken(null);
          onSuccess?.(context, metadata);
        }}
        onExit={(...args) => {
          setToken(null);
          onExit?.(...args);
        }}
        onEvent={onEvent}
      />
    );
  }, [onError, onEvent, onExit, onReady, onSuccess, token]);

  return {
    ready,
    connect: connectPlaid,
    creatingToken: isCreatingToken,
    connectorNode
  };
};
