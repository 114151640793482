import '@rainbow-me/rainbowkit/styles.css';
import { RainbowKitProvider, lightTheme, useConnectModal } from '@rainbow-me/rainbowkit';
import { ResolvedRegister, WagmiProvider, useAccount, useWalletClient } from 'wagmi';
import { ReactNode, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WalletBase } from '../../WalletBase';
import { getAccount, signMessage } from 'wagmi/actions';
import { EventEmitter } from 'events';
import { ISignResult } from '../../types';
import { get4361Message, prepareSignMessage } from '../../utils';
import { proxy } from 'valtio';

const queryClient = new QueryClient();

const eventBus = new EventEmitter();

const EVM_EVENT_KEY = 'evm_signin';

interface EVMStoreType {
  account: ReturnType<typeof useAccount> | null;
  connectModal: ReturnType<typeof useConnectModal> | undefined;
  walletClient: ReturnType<typeof useWalletClient> | undefined;
}

const evmStore = proxy<EVMStoreType>({
  account: null,
  connectModal: undefined,
  walletClient: undefined
});

export class EvmWallet extends WalletBase {
  static config: ResolvedRegister['config'];

  private eventKey = EVM_EVENT_KEY;

  connect() {
    console.log(evmStore.connectModal, 'evm');
    evmStore?.connectModal?.openConnectModal?.();
  }

  async sign(_message: string) {
    const sign = await signMessage(EvmWallet.config, {
      message: _message
    });
    return {
      message: _message,
      signature: sign
    };
  }

  override async signin(statement?: string): Promise<ISignResult> {
    return new Promise((resolve) => {
      const signCallback = (data: ReturnType<typeof useAccount>) => {
        this.address = data.address;
        this.isConnected = true;
        this.chainId = data.chainId;
        const msg = prepareSignMessage({ statement, chainId: data.chainId!, address: data.address! });
        const fullMessage = get4361Message(msg);

        const res = this.sign(fullMessage);

        resolve(res);
      };

      const account = getAccount(EvmWallet.config);

      console.log(account, 'acc');

      if (account?.isConnected) {
        signCallback(account);
        return;
      }

      this.connect();
      eventBus.once(this.eventKey, (data) => {
        console.log('listen event key success, event key = %s, data = %j', this.eventKey, data);
        signCallback(data);
      });
    });
  }
}

export const EVMConnector = () => {
  const connectModal = useConnectModal();
  const walletClient = useWalletClient();
  const account = useAccount();
  evmStore.connectModal = connectModal;
  evmStore.walletClient = walletClient;

  useEffect(() => {
    if (account.isConnected) {
      eventBus.emit(EVM_EVENT_KEY, account);
    }
  }, [account]);

  return null;
};

export const EvmProvider = ({ children, config }: { children: ReactNode; config: ResolvedRegister['config'] }) => {
  EvmWallet.config = config;
  return (
    <QueryClientProvider client={queryClient}>
      <WagmiProvider config={config}>
        <RainbowKitProvider
          theme={lightTheme({
            accentColor: '#DD8D58'
          })}
        >
          {children}
          <EVMConnector />
        </RainbowKitProvider>
      </WagmiProvider>
    </QueryClientProvider>
  );
};
