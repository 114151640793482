import { ENVS } from '@/constants/config.ts';
import {
  AuthenticationStatus,
  createAuthenticationAdapter,
  getDefaultConfig,
  RainbowKitAuthenticationProvider,
  RainbowKitProvider
} from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode, useState } from 'react';
import { SiweMessage } from 'siwe';
import { http, WagmiProvider } from 'wagmi';
import { mainnet, sepolia } from 'wagmi/chains';
import { signIn, signOut } from './services';
import { getCustomNaNoId } from './utils/common';

const chains = [mainnet, sepolia];

// eslint-disable-next-line react-refresh/only-export-components
export const wagmiConfig = getDefaultConfig({
  appName: 'SP-AssetProof-App',
  projectId: ENVS.WALLET_CONNECT_PROJECT_ID!,
  chains: chains as any,
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http()
  }
});

const queryClient = new QueryClient();

export const Web3Provider = ({ children }: { children: ReactNode }) => {
  const [authStatus, setAuthStatus] = useState<AuthenticationStatus>('unauthenticated');
  const authenticationAdapter = createAuthenticationAdapter({
    getNonce: async () => {
      const nonce = getCustomNaNoId();
      return nonce;
    },

    createMessage: ({ nonce, address, chainId }) => {
      return new SiweMessage({
        domain: window.location.host,
        address,
        statement: 'Sign Protocol',
        uri: window.location.origin,
        version: '1',
        chainId,
        nonce
      });
    },

    getMessageBody: ({ message }) => {
      return message.prepareMessage();
    },

    verify: async ({ message, signature }) => {
      setAuthStatus('loading');
      const verifyRes: any = await signIn({
        message: message.toMessage(),
        signature,
        chainType: 'evm',
        client: 'MetaMask',
        key: message.address
      });

      if (verifyRes?.logged) {
        setAuthStatus('authenticated');
        // setAddress(verifyRes.userId);
        return Boolean(verifyRes.logged);
      } else {
        setAuthStatus('unauthenticated');
        return false;
      }
    },

    signOut: async () => {
      await signOut();
      setAuthStatus('unauthenticated');
      //   setAddress('' as any);
    }

  });
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitAuthenticationProvider adapter={authenticationAdapter} status={authStatus}>
          <RainbowKitProvider>
            {children}
          </RainbowKitProvider>
        </RainbowKitAuthenticationProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};
