import { Edit02 } from '@ethsign/icons';
import { Input, Modal } from '@ethsign/ui';
import { useEffect } from 'react';
import { useState } from 'react';

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  name?: string;
  onConfirm: (name: string) => void;
};

export const EditReportNameModal = (props: Props) => {
  const { open, onOpenChange, name, onConfirm } = props;
  const [reportName, setReportName] = useState(name || '');
  useEffect(() => {
    setReportName(name || '');
  }, [name]);
  return (
    <Modal
      open={open}
      onOpenChange={onOpenChange}
      confirmButtonProps={{
        variant: 'primary'
      }}
      onConfirm={() => {
        onConfirm(reportName);
      }}
    >
      <div>
        <div className="flex size-12 items-center justify-center rounded-full bg-secondary">
          <Edit02 color="#0052FF" size={24} />
        </div>
        <div className="mt-5 font-medium text-lg text-gray-900">Report Settings</div>
        <div className="mt-2 text-sm font-normal text-gray-500">Name you report for easy identification.</div>
        <div className="mt-8 font-medium text-sm text-gray-900">
          <span>Report Name</span>
          <div className="mt-2 text-xs font-normal text-gray-500">Report name should not exceed 50 characters.</div>
          <Input
            className="mt-[6px]"
            defaultValue={name}
            value={reportName}
            placeholder="Please enter a name"
            onChange={(e) => {
              setReportName(e.target.value);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
