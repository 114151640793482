import { CheckboxMultiple } from '@/components/CheckboxMultiple';
import { OffchainBalanceItem, OnChainBalanceItem, ReportAccount } from '@/types/proof';
import { useAxiomCircuit } from '@axiom-crypto/react';
import { getChainInfo, handleAmount } from '@/utils/common';
import { File07, Plus } from '@ethsign/icons';
import { Button, Modal } from '@ethsign/ui';
import { useEffect, useState } from 'react';
import jsonInputs from '@/axiom/data/inputs.json';
import { getBlockNumber, waitForTransactionReceipt, writeContract } from '@wagmi/core';
import { wagmiConfig } from '@/Web3Provider';
import { useCurrentUser } from '@/components/UserProvider';
import { Constants } from '@/constants';
import { sepolia } from 'viem/chains';
import { bytes32 } from '@/lib/utils';

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onChainAssets?: OnChainBalanceItem[];
  offChainAssets?: OffchainBalanceItem[];
  onGenerate: (reportAccouts: ReportAccount[]) => void;
};
const callbackTarget = Constants[sepolia.id].CALLBACK_CONTRACT;

export const GenerateReportModal = (props: Props) => {
  const { open, onOpenChange, onChainAssets, offChainAssets, onGenerate } = props;
  const [selectedAssets, setSelectedAssets] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { user } = useCurrentUser();
  useEffect(() => {
    setSelectedAssets(onChainAssets?.map((item) => ({ ...item, label: item.address, value: item.address })) || []);
  }, [onChainAssets]);

  useEffect(() => {
    setSelectedOffChainAssets(offChainAssets?.map((item) => ({ ...item, label: item.id, value: item.id })) || []);
  }, [offChainAssets]);
  const [selectedOffChainAssets, setSelectedOffChainAssets] = useState<any[]>([]);
  const reportAccouts = selectedAssets
    .filter((item) => item.checked)
    .map((item) => ({
      accountType: 'onchain',
      accountId: item.value
    }))
    .concat(
      selectedOffChainAssets
        .filter((item) => item.checked)
        .map((item) => ({
          accountType: 'bank',
          accountId: item.id
        }))
    ) as ReportAccount[];

  const totalAmount: number =
    selectedAssets
      .filter((item) => item.checked)
      .reduce(
        (acc: any, item: any) =>
          acc + Number(item.tokens.reduce((acc: any, token: any) => acc + Number(token.value), 0)),
        0
      ) +
    selectedOffChainAssets
      .filter((item) => item.checked)
      .reduce((acc: any, item: any) => acc + Number(item.totalValue || 0), 0);

  const { build, setParams, areParamsSet } = useAxiomCircuit<typeof jsonInputs>();
  const getAxiomData = async () => {
    if (!user?.userId) return;
    try {
      const blockNumber = await getBlockNumber(wagmiConfig, { chainId: sepolia.id });
      const inputs = {
        blockNumber: Number(blockNumber),
        address: user.userId
      };
      setParams(inputs, callbackTarget, bytes32(user.userId), user.userId);
    } catch (error) {
      console.error('error', error);
    }
  };
  useEffect(() => {
    const buildQuery = async () => {
      const data = await build();
      console.log('data', data);
      const tx = await writeContract(wagmiConfig, data as any);
      console.log('request', tx);
      const res = await waitForTransactionReceipt(wagmiConfig, { hash: tx });
      console.log(res, 'result');
    };
    if (areParamsSet) {
      buildQuery();
    }
  }, [areParamsSet]);

  return (
    <Modal footer={false} open={open} onOpenChange={onOpenChange}>
      <div>
        <div className="flex size-12 items-center justify-center rounded-full bg-secondary">
          <File07 color="#0052FF" size={24} />
        </div>
        <div className="mt-5 font-medium text-lg text-gray-900">Generating Proof Of Funds Report</div>
        <div className="mt-2 text-sm font-normal text-gray-500">
          Connect wallet address to generate proof of funds report with your onchain assets.{' '}
        </div>
        <div className="mt-6 font-medium text-lg text-gray-900">
          Total Selected Assets: <span className="text-secondary-foreground">{totalAmount.toFixed(2)} USD</span>
        </div>
        <div className="max-h-[400px] overflow-auto">
          <div className="mt-6  font-medium text-md text-gray-700">
            Onchain Assets
            <CheckboxMultiple
              className="mt-4"
              data={selectedAssets}
              onChange={(data) => {
                setSelectedAssets(data);
              }}
              itemRenderer={(item) => {
                const chainInfo = getChainInfo('1');
                const totalAmout: number = item.tokens.reduce((acc: any, token: any) => acc + Number(token.value), 0);
                return (
                  <div className="flex flex-1 items-center gap-2 rounded-lg p-4">
                    <img crossOrigin="anonymous" className="size-6" src={chainInfo?.icon}></img>
                    <div>
                      <div className="font-medium text-xs text-gray-700">{item.address}</div>
                      <div className="font-medium text-xs text-gray-500">Asset Worth: {totalAmout.toFixed(2)} USD</div>
                    </div>
                  </div>
                );
              }}
            ></CheckboxMultiple>
          </div>
          {offChainAssets && offChainAssets?.length > 0 && (
            <div className="mt-6 font-medium text-md text-gray-700">
              Offchain Assets
              <CheckboxMultiple
                className="mt-4"
                data={selectedOffChainAssets}
                onChange={(data) => {
                  setSelectedOffChainAssets(data);
                }}
                itemRenderer={(item) => {
                  return (
                    <div className="flex flex-1 items-center gap-2 rounded-lg border border-gray-200 p-4">
                      <img crossOrigin="anonymous" className="size-6" src={item.institution.logo}></img>
                      <div>
                        <div className="font-medium text-xs text-gray-700">{item.institution.name}</div>
                        <div className="font-medium text-xs text-gray-500">
                          Asset Worth: {handleAmount(item.totalValue)} USD
                        </div>
                      </div>
                    </div>
                  );
                }}
              ></CheckboxMultiple>
            </div>
          )}
        </div>
        <div className="mt-6 flex justify-between">
          {/* <Button size="lg" variant="outline">
            <Plus size={20} color="" /> Add Asset
          </Button> */}

          <div className=""></div>
          <Button
            loading={loading}
            size="lg"
            onClick={async () => {
              // getAxiomData();
              // return;
              setLoading(true);
              try {
                await onGenerate(reportAccouts);
                setLoading(false);
              } catch (error) {
                setLoading(false);
              }
            }}
          >
            Generate
          </Button>
        </div>
      </div>
    </Modal>
  );
};
