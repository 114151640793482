import React from 'react';
import cls from 'classnames';

export const LoadingCircle: React.FC = () => {
  return (
    <svg
      className="animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
    >
      <path
        d="M20 2.375C22.3802 2.375 24.7371 2.84382 26.9361 3.75468C29.1352 4.66555 31.1332 6.00063 32.8163 7.68369C34.4994 9.36675 35.8345 11.3648 36.7453 13.5639C37.6562 15.7629 38.125 18.1198 38.125 20.5C38.125 22.8802 37.6562 25.2371 36.7453 27.4361C35.8344 29.6352 34.4994 31.6333 32.8163 33.3163C31.1332 34.9994 29.1352 36.3345 26.9361 37.2453C24.7371 38.1562 22.3802 38.625 20 38.625C17.6198 38.625 15.2629 38.1562 13.0639 37.2453C10.8648 36.3344 8.86675 34.9994 7.18368 33.3163C5.50062 31.6332 4.16555 29.6352 3.25468 27.4361C2.34381 25.2371 1.875 22.8802 1.875 20.5C1.875 18.1198 2.34382 15.7629 3.25469 13.5639C4.16556 11.3648 5.50063 9.36674 7.1837 7.68368C8.86676 6.00062 10.8648 4.66554 13.0639 3.75468C15.2629 2.84381 17.6198 2.375 20 2.375L20 2.375Z"
        stroke="#F2F4F7"
        strokeWidth="3.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 2.375C23.8276 2.375 27.557 3.58675 30.6536 5.83657C33.7502 8.08639 36.0551 11.2588 37.2379 14.8991C38.4207 18.5394 38.4207 22.4606 37.2379 26.1009C36.0551 29.7412 33.7502 32.9136 30.6536 35.1634"
        stroke="#0052FF"
        strokeWidth="3.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LoadingMask: React.FC<{ className: string }> = (props) => {
  const { className } = props;

  return (
    <div className={cls('absolute inset-0', className)}>
      <LoadingCircle />
    </div>
  );
};
