import { PasswordInput } from '@/components/PasswordInput';
import { dateFormat } from '@/utils/common';
import { InfoCircle } from '@ethsign/icons';
import { Modal, toast } from '@ethsign/ui';
import { useState } from 'react';

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  address: string;
  totalAssets: string;
  onConfirm: (password: string) => void;
};

export const AttestReportModal = (props: Props) => {
  const { open, onOpenChange, address, totalAssets, onConfirm } = props;
  const [password, setPassword] = useState('' as string);
  return (
    <Modal
      open={open}
      onOpenChange={onOpenChange}
      confirmButtonProps={{
        variant: 'primary'
      }}
      onConfirm={() => {
        if (!password) {
          toast({
            title: 'Error',
            description: 'Please enter your password',
            variant: 'error'
          });
          return;
        }
        onConfirm(password);
      }}
    >
      <div>
        <div className="flex size-12 items-center justify-center rounded-full bg-secondary">
          <InfoCircle color="#0052FF" size={24} />
        </div>
        <div className="mt-5 font-medium text-lg text-gray-900">Attest your proof of fund report</div>
        <div className="mt-2 text-sm font-normal text-gray-500">
          Please attest the following information is consistent with your proof of fund report
        </div>
        <div className="mt-5 flex flex-col gap-4">
          <div>
            <div className="text-sm font-normal text-gray-500">Address:</div>
            <div className="font-medium text-xs text-gray-700">{address}</div>
          </div>
          <div>
            <div className="text-sm font-normal text-gray-500">Total Assets:</div>
            <div className="font-medium text-xs text-gray-700">${totalAssets}</div>
          </div>
          <div>
            <div className="text-sm font-normal text-gray-500">Time Stamp</div>
            <div className="font-medium text-xs text-gray-700">{dateFormat(new Date(), 'datetime')}</div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-sm font-normal text-gray-500">Password</div>
            <div className="font-medium text-xs text-gray-700">
              <PasswordInput
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              ></PasswordInput>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
