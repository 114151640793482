import { OffchainBalanceItem, ReportAccount } from '@/types/proof';
import { apiClient } from '@/utils/api-client';

///pof/reports
export const getReportList = async () => {
  return apiClient.get<any>(`/pof/reports`);
};

///pof/reports/POF_xpMUCLrkAfawlsfVtUBgh
export const getReportDetail = async (id: string) => {
  return apiClient.get<any>(`/pof/reports/${id}`);
};

// pof/reports
// {
//     "reportType": "all",
//     "onchainData": [
//         {
//             "address": "0x1123",
//             "network": "Ethereum",
//             "symbolName": "USDT",
//             "amount": "99.99"
//         }
//     ]
// }

export interface OnChainDataItem {
  address: string;
  totalValue: string;
  tokens: {
    network: string;
    token: string;
    value: string;
    balance: string;
  }[];
}

export const createReport = async (data: { accounts: ReportAccount[]; reportName: string }) => {
  return apiClient.post<any>(`/pof/reports`, data);
};

export const attestReport = async (reportId: string, password: string) => {
  return apiClient.post<any>(`/pof/reports/${reportId}/attest`, { password });
};

export const modifyReport = async (data: { reportName: string; reportId: string }) => {
  return apiClient.post<any>(`/pof/reports/${data.reportId}`, { reportName: data.reportName });
};

//pof/onchain/balance-list
export const getOnchainBalanceList = async (address?: string) => {
  return apiClient.get<any>(`/pof/onchain/balance-list${address ? `?address=${address}` : ''}`);
};

export const linkBankAccount = async (props: { publicToken: string, institutionId: string }) => {
  const { institutionId, publicToken } = props
  return apiClient.post(`/pof/bank/link-account`, {
    publicToken,
    institutionId
  });
};

export const linkOnChainAccount = async (params: { address: string; signature: string; message: string }) => {
  return apiClient.post(`/pof/onchain/link-account`, params);
};

export const disconnectWallet = async (address: string) => {
  return apiClient.post(`/pof/onchain/cancel-link-account`, { address });
};

export const disconnectBankAccount = async (id: number) => {
  return apiClient.post('/pof/bank/cancel-link-account', { id });
};

export const getOffChainBalanceList = async (id?: string) => {
  return apiClient.get<{
    rows: OffchainBalanceItem[];
    totalValue: string;
  }>(`/pof/bank/balance-list${id ? `?id=${id}` : ''}`);
};
