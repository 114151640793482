import { wagmiConfig } from '@/Web3Provider';
import { linkBankAccount, linkOnChainAccount } from '@/services/proof';
import { Plus } from '@ethsign/icons';
import { Button, toast } from '@ethsign/ui';
import { connect, getWalletClient, injected } from '@wagmi/core';
import React from 'react';
import { usePlaidConnector } from '../../../../components/base/PlaidLinkConnector';
import { showProgressModal, showResultModal } from '../../../../components/base/ResultModal';
import { Events, eventBus } from '../../PageCtrl';

// eslint-disable-next-line react-refresh/only-export-components
export async function signAddOnChainAssetsMessage() {
  const accountResult = await connect(wagmiConfig, { connector: injected() });

  const address = accountResult.accounts[0];
  const walletClient = await getWalletClient(wagmiConfig, {
    account: address
  });

  const typedData: Parameters<typeof walletClient.signTypedData>[0] = {
    types: {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' }
      ],
      Data: [
        { name: 'message', type: 'string' },
        { name: 'address', type: 'address' },
        { name: 'issueAt', type: 'string' }
      ]
    },
    primaryType: 'Data',
    domain: {
      name: 'PoF',
      version: '1',
      chainId: 1
    },
    message: {
      message: `You are binding ${address} to account`,
      address: address,
      issueAt: Date.now().valueOf().toString()
    }
  };

  const data = await walletClient?.signTypedData(typedData);

  return {
    address,
    signature: data,
    message: JSON.stringify(typedData)
  };
}

async function addOnchainAsset() {
  const loadingModalRef = showProgressModal({
    title: 'Connecting Wallet',
    content: 'Please confirm the transaction from your wallet'
  });

  try {
    const signResult = await signAddOnChainAssetsMessage();

    await linkOnChainAccount(signResult);

    showResultModal({
      type: 'success',
      title: 'Success',
      content: `Wallet Address [${signResult.address}] successfully added to your onchain asset.`,
      actionText: 'OK',
      onAction({ hide }) {
        eventBus.emit(Events.ONCHAIN_ASSET_ADDED);
        hide();
      }
    });

    loadingModalRef.close();
  } catch (error: any) {
    loadingModalRef.close();

    if (error.name === 'UserRejectedRequestError') return;

    showResultModal({
      type: 'failed',
      title: 'Failed',
      content: error.message,
      actionText: 'OK',
      onAction({ hide }) {
        hide();
      }
    });
  }
}

export const AddAssetButton: React.FC<{ active: boolean; type: 'onchain' | 'offchain' }> = (props) => {
  const { active, type } = props;

  const { connect, connectorNode, ready } = usePlaidConnector({
    onReady({ open: openPlaid }) {
      openPlaid();
    },
    onExit(error) {
      if (error !== null) {
        toast({
          title: error.error_type,
          description: error.display_message || error.error_message || error.error_code,
          variant: 'error',
          duration: 1000
        });

        console.error(error);
      }
    },
    async onSuccess(publicToken, metadata) {
      await linkBankAccount({
        publicToken,
        institutionId: metadata.institution?.institution_id ?? ''
      });
      eventBus.emit(Events.OFFCHAIN_ASSET_ADDED);
    }
  });

  return (
    <>
      <Button
        loading={type === 'offchain' && !ready}
        size="sm"
        variant={active ? 'primary' : 'outline'}
        className={!active ? 'text-black' : ''}
        onClick={(e) => {
          e.stopPropagation();

          if (type === 'onchain') return addOnchainAsset();

          if (type === 'offchain') return connect();
        }}
      >
        <Plus className="mr-2" color={active ? 'white' : 'black'} size={20} />
        Add
      </Button>

      {connectorNode}
    </>
  );
};
