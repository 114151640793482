import { OffchainBalanceItem } from '@/types/proof';
import { Bank } from '@ethsign/icons';
import React, { useMemo } from 'react';
import { EmptyView } from './EmptyView';
import { OffchainAssetCard } from './OffchainAssetCard';

interface OffchainViewProps {
  data: OffchainBalanceItem[];
}

export const OffchainView: React.FC<OffchainViewProps> = (props: OffchainViewProps) => {
  const { data } = props;

  const isEmpty = useMemo(() => {
    return data.length === 0;
  }, [data.length]);

  return (
    <>
      {isEmpty && (
        <EmptyView
          icon={<Bank color="hsl(var(--primary))" size={24} />}
          title="No Offchain Asset"
          description=" There is no offchain asset connected. Add your offchain asset through plaid by clicking the add asset button"
        />
      )}

      {!isEmpty && data.map((asset, index) => <OffchainAssetCard key={index} asset={asset} />)}
    </>
  );
};
