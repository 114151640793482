import { MenuItem } from "@/components/Menu";
import { Home02 } from "@ethsign/icons";
import { useNavigate } from "react-router-dom";

export const SideNav = () => {
    const navigate = useNavigate();
    return (
        <div className="relative min-w-[250px] w-[250px] bg-white h-full px-2 py-8 flex flex-col justify-between border-r border-gray-200">
            <div className=" flex flex-col flex-1 overflow-y-hidden">
                <div
                    onClick={() => {
                        navigate('/dashboard');
                    }}
                >
                    <MenuItem
                        icon={<Home02 size={16} color="#344054"></Home02>}
                        isActive={true}
                    >
                        <span>
                            Dashboard
                        </span>
                    </MenuItem>
                </div>
            </div>
        </div>
    )
}