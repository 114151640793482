import { disconnectBankAccount } from '@/services/proof';
import { OffchainBalanceItem } from '@/types/proof';
import { Eye, EyeOff, LogIn04, Trash01 } from '@ethsign/icons';
import { Button, Modal, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@ethsign/ui';
import React, { useMemo, useState } from 'react';
import { showResultModal } from '../../../../components/base/ResultModal';
import { Events, eventBus } from '../../PageCtrl';

const formatBankAccountType = (type: string) => type[0].toUpperCase() + type.slice(1);

export interface OffchainAsset {
  id: number;
  icon: string;
  total: string;
  name: string;
  number: string;
  type: string;
  balance: string;
  currency: string;
  lastRetrieveTime: string;
}

const DisconnectModal: React.FC<{
  open: boolean;
  asset: OffchainBalanceItem;
  onOpenChange: (visible: boolean) => void;
  onSuccess?: () => void;
}> = (props) => {
  const { onOpenChange, open, onSuccess, asset } = props;

  const [loading, setLoading] = useState(false);

  const cards = useMemo(() => {
    return asset.accounts.map((item) => [
      { label: 'Account Number', value: '**** ' + item.mask },
      { label: 'Account Type', value: formatBankAccountType(item.subtype) }
    ]);
  }, [asset]);

  const disconnect = async () => {
    try {
      setLoading(true);
      await disconnectBankAccount(asset.id);

      onSuccess?.();
      setLoading(false);
      onOpenChange(false);

      showResultModal({
        type: 'success',
        title: 'Success',
        content: `${asset.institution?.name} has been successfully disconnected.`,
        actionText: 'OK',
        onAction({ hide }) {
          eventBus.emit(Events.OFFCHAIN_ASSET_DISCONNECT);
          hide();
        }
      });
    } catch (error: any) {
      showResultModal({
        type: 'failed',
        title: 'Failed to disconnect',
        content: error.message,
        actionText: 'Retry',
        onAction: async ({ hide }) => {
          hide();
          disconnect();
        }
      });
    }
  };

  return (
    <Modal
      className="!w-[400px] !gap-y-0"
      open={open}
      footer={false}
      onOpenChange={(visible) => {
        onOpenChange?.(visible);
      }}
      maskClosable={!loading}
      hiddenCloseIcon={loading}
    >
      <div className="flex items-center justify-start">
        <div className="flex size-16 items-center justify-center rounded-full border-[10px] border-[#F8FAFF] bg-[#ECF2FF]">
          <Trash01 color="hsl(var(--primary))" size={32} />
        </div>
      </div>
      <div className="mt-4">
        <strong className="text-[19px] leading-7">Disconnect Bank Account</strong>
        <p className="mt-2 text-gray-500">Please confirm the account information</p>
      </div>

      {/* <div className="mt-4 space-y-4 text-[15px] font-normal leading-5">
        <div className="">
          <p className="text-gray-500">Account Number:</p>
          <p className="font-normal text-gray-700">{asset.number}</p>
        </div>

        <div className="">
          <p className="text-gray-500">Account Type</p>
          <p className="font-normal text-gray-700">{asset.type}</p>
        </div>
      </div> */}

      <div className="">
        {cards.map((item, index) => (
          <div
            key={index}
            className="mt-4 flex items-center justify-between border-b border-solid border-t-gray-500 pb-5 text-[15px] font-normal leading-5 last:border-none"
          >
            {item.map((info, index) => (
              <div key={index} className="">
                <p className="text-gray-500">{info.label}</p>
                <p className="font-normal text-gray-700">{info.value}</p>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="flex items-center justify-end gap-x-3">
        <Button
          disabled={loading}
          variant="outline"
          className="mt-6 text-primary"
          onClick={() => {
            onOpenChange?.(false);
          }}
        >
          Cancel
        </Button>
        <Button loading={loading} variant="primary" className="mt-6" onClick={disconnect}>
          Disconnect
        </Button>
      </div>
    </Modal>
  );
};

export const OffchainAssetCard: React.FC<{ asset: OffchainBalanceItem; onDisconnect?: () => void }> = (props) => {
  const { asset, onDisconnect } = props;

  const [disconnectModalVisible, setDisconnectModalVisible] = useState(false);

  return (
    <div className="rounded-2xl bg-white shadow-[0px_4px_20px_0px_rgba(15,34,212,0.12)]">
      <div className="relative flex items-center justify-between px-6 py-5">
        <div className="flex items-center gap-5">
          <div className="h-[44px] w-[62px] overflow-hidden">
            {asset.institution.logo && (
              <img
                className="block size-full object-contain object-center"
                crossOrigin="anonymous"
                src={asset.institution.logo}
              />
            )}
          </div>

          <div className="space-y-[5px]">
            <h3 className="m-0 flex items-center justify-start text-[21px] font-semibold leading-[30px]">
              <span>Total Assets: ${asset.totalValue}</span>
            </h3>

            <div className="text-[15px] font-semibold leading-5 text-black/70">{asset.institution.name}</div>
          </div>
        </div>

        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            className="border-gray-200"
            onClick={() => {
              setDisconnectModalVisible(true);
            }}
          >
            <LogIn04 className="mr-2" size={20} />
            <span>Disconnect</span>
          </Button>
        </div>
      </div>

      <AssetCardsTable asset={asset} />

      <DisconnectModal
        asset={asset}
        open={disconnectModalVisible}
        onOpenChange={(visible) => {
          setDisconnectModalVisible(visible);
        }}
        onSuccess={() => {
          onDisconnect?.();
        }}
      />
    </div>
  );
};

const AccountNumber: React.FC<{ accountNumber: string }> = (props) => {
  const [masked, setMasked] = useState(false);

  const accountNumber = useMemo(() => {
    const parts = props.accountNumber.split(' ');

    return (
      <div className="flex items-center text-xs">
        <span className="inline-block min-w-[3em]">{masked ? '****' : parts[0]}</span>
        <span>{parts[1]}</span>
      </div>
    );
  }, [masked, props.accountNumber]);

  return (
    <div className="flex items-center gap-3">
      {accountNumber}

      <span className="cursor-pointer" onClick={() => setMasked((old) => !old)}>
        {masked ? <Eye size={16} /> : <EyeOff size={16} />}
      </span>
    </div>
  );
};

const AssetCardsTable: React.FC<{ asset: OffchainBalanceItem }> = (props) => {
  const { asset } = props;

  return (
    <Table className="border-t border-solid border-gray-300 [&_td]:!px-6 [&_th]:!px-6">
      <TableHeader className="border-b border-solid border-gray-100 font-semiBold text-xs [&>th]:!text-black">
        <TableRow>
          <TableHead>Account Number</TableHead>
          <TableHead>Account Type</TableHead>
          <TableHead>Balance</TableHead>
        </TableRow>
      </TableHeader>

      <TableBody>
        {asset.accounts.map((card, index) => (
          <TableRow key={index} className="text-xs font-normal">
            <TableCell>
              {/* TODO: 替换为真实的卡号 */}
              {/* <AccountNumber accountNumber={'1233 1233'} /> */}
              <span>**** </span>
              <span>{card.mask}</span>
            </TableCell>
            <TableCell>{formatBankAccountType(card.subtype)}</TableCell>
            <TableCell>{card.availableBalance} USD</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
