import { hideGlobalLoading, showGlobalLoading } from '@/components/Loading';
import { showResultModal } from '@/components/base/ResultModal';
import { attestReport, getReportDetail, modifyReport } from '@/services/proof';
import { ReportDetail } from '@/types/proof';
import { ControllerBase } from '@/utils/bizify';

export type PageData = {
  loading: boolean;
  reportDetailData: ReportDetail;
  editReportNameModalOpen: boolean;
  attestReportModalOpen: boolean;
};

export class PageCtrl extends ControllerBase<PageData> {
  protected $data(): PageData {
    return {
      loading: false,
      editReportNameModalOpen: false,
      attestReportModalOpen: false,
      reportDetailData: {} as ReportDetail
    };
  }
  async getData(id: string) {
    showGlobalLoading();
    try {
      const res = await getReportDetail(id);
      this.state.reportDetailData = res || {};
      hideGlobalLoading();
    } catch (error) {
      hideGlobalLoading();
      console.error(error);
    }
  }

  setData(data: Partial<PageData>) {
    Object.assign(this.state, data);
  }

  async attestReport(id: string, password: string) {
    showGlobalLoading();
    try {
      await attestReport(id, password);
      await this.getData(id);
      showResultModal({
        title: 'Success',
        content: 'You have successfully made an attestation of your asset.',
        type: 'success',
        actionText: 'View Report',
        onAction: () => {
          window.location.href = `/preview/${id}`;
        }
      });
      this.state.attestReportModalOpen = false;

      hideGlobalLoading();
    } catch (error) {
      hideGlobalLoading();
      console.error(error);
    }
  }

  async editReportName(name: string) {
    showGlobalLoading();
    const reportId = this.state.reportDetailData.id;
    const res = await modifyReport({ reportName: name, reportId });
    if (res) {
      this.getData(reportId);
      this.state.editReportNameModalOpen = false;
    }
  }
}
