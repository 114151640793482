import React from 'react';

export const EmptyView: React.FC<{ icon: React.ReactNode; title: string; description: string }> = (props) => {
  const { description, icon, title } = props;
  return (
    <div className="rounded-[20px] bg-white">
      <div className="mx-auto w-[416px] space-y-4 px-6 py-8">
        <div className="flex items-center justify-center">
          <div className="flex size-12 items-center justify-center rounded-full bg-secondary">{icon}</div>
        </div>

        <div className="text-center">
          <strong className="font-bold text-[17px] leading-6">{title}</strong>

          <p className="text-[15px] font-normal leading-5 text-gray-500">{description}</p>
        </div>
      </div>
    </div>
  );
};
