import * as _chains from 'viem/chains';
import {
  Chain,
  polygonMumbai,
  sepolia,
} from 'viem/chains';

const chains = { ..._chains };

export function getChainById(chainId: number): Chain {
  for (const chain of Object.values(chains)) {
    if ('id' in chain) {
      if (chain.id == chainId) {
        return chain;
      }
    }
  }

  throw new Error(`Chain with id ${chainId} not found`);
}

export function getBlockExplorerByChainId(chainId: number): string {
  const chain = getChainById(chainId);

  return chain.blockExplorers?.default?.url || chain.blockExplorers?.etherscan?.url || '';
}

export { polygonMumbai, sepolia };

export const MAINNET_CHAINS = [];
export const TESTNET_CHAINS = [sepolia, polygonMumbai];
export const MAINNET_AND_TESTNET_CHAINS = [...MAINNET_CHAINS, ...TESTNET_CHAINS];
export const ALL_CHAINS = MAINNET_AND_TESTNET_CHAINS;

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
