import AxiomProvider from '@/AxiomProvider';
import BuildQuery from '@/components/OnChainProof/BuildQuery';
import { Constants } from '@/constants';
import callbackAbi from '@/lib/abi/AverageBalance.json';
import { bytes32 } from '@/lib/utils';
import { useState } from 'react';
import { useAccount, usePublicClient } from 'wagmi';
import { NetworkCheckButton } from '@/components/Button/NetworkCheckButton';
import { wagmiConfig } from '@/Web3Provider';
import { getBlockNumber } from '@wagmi/core';

function OnChainProof() {
  const { address, chain } = useAccount();
  const publicClient = usePublicClient();
  const [blockNumber, setBlockNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  async function updateBlockNumber() {
    console.log(publicClient, 'cc');
    try {
      setLoading(() => true);
      const block = await getBlockNumber(wagmiConfig);
      console.log('block', block);
      setBlockNumber(() => Number(block));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(() => false);
    }
  }

  const inputs: any = {
    blockNumber: Number(blockNumber),
    address: address!
  };
  console.log(inputs);
  console.log(chain.id, 'Constants[chain.id]');

  return (
    <div>
      <div className="mt-[100px] flex flex-col items-center justify-center font-bold">Prove</div>
      <div className="text-center">Please wait while your browser generates a compute proof for the Axiom Query.</div>
      <div className="flex flex-col items-center gap-2">
        {!address || blockNumber === 0 || !chain ? (
          <div className="mt-20 flex w-full items-center justify-center">
            <NetworkCheckButton loading={loading} onClick={() => updateBlockNumber()}>
              Build Axiom query
            </NetworkCheckButton>
          </div>
        ) : (
          <BuildQuery
            inputs={inputs}
            callbackTarget={Constants[chain.id].CALLBACK_CONTRACT}
            callbackExtraData={bytes32(address)}
            refundee={address}
            callbackAbi={callbackAbi}
          />
        )}
      </div>
    </div>
  );
}

export default function Axiom() {
  return (
    <AxiomProvider>
      <OnChainProof />
    </AxiomProvider>
  );
}
