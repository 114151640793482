
import { ReactElement } from 'react';

interface Props {
    icon?: ReactElement;
    isActive?: boolean;
    children: ReactElement | string;
    rightIcon?: ReactElement;
    isFolder?: boolean;
}

export function MenuItem(props: Props) {
    const { icon, isActive, children, rightIcon, isFolder } = props;
    return (
        <div
            className={`cursor-pointer h-11 flex flex-row items-center justify-between ${isFolder ? 'pl-9' : 'pl-3'} pr-2 ${isActive ? 'bg-primary-50' : ''
                }`}
        >
            <div className="flex flex-row items-center min-h-5 flex-1">
                {icon}
                <div className="text-sm font-medium text-gray-700 ml-3 flex flex-row items-center flex-1">{children}</div>
            </div>
            {rightIcon}
        </div>
    );
}