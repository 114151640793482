import { ChainType, WalletFactory } from '@/core/wallet-sdk';
import { Events, eventBus } from '@/pages/home/PageCtrl';
import { signIn, signOut } from '@/services/user';
import { DotsVertical, LogOut02 } from '@ethsign/icons';
import { Dropdown, toast } from '@ethsign/ui';
import { shortenWalletAddress } from '@ethsign/utils-web';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { MetaMaskIcon } from '../Icons';
import { hideGlobalLoading, showGlobalLoading } from '../Loading';
import { useCurrentUser } from '../UserProvider';

export const Logo: React.FC = () => {
  return (
    <div className="flex flex-col gap-1">
      <img
        className="h-[42px] w-[167px]"
        crossOrigin="anonymous"
        src="https://sign-public-cdn.s3.us-east-1.amazonaws.com/PoF/logo-pof_240813061733.svg"
      ></img>
    </div>
  );
};

export function Header() {
  const { user, fetchUser } = useCurrentUser();

  const handleLogOut = async () => {
    showGlobalLoading();

    try {
      await signOut();
      await fetchUser();

      eventBus.emit(Events.SIGN_OUT);

      hideGlobalLoading();
    } catch (error) {
      hideGlobalLoading();
      throw error;
    }
  };

  const username = useMemo(() => {
    if (!user) return '';

    return shortenWalletAddress(user.userId ?? '', 'shorter');
  }, [user]);

  const handleConnect = async (chainType: ChainType) => {
    try {
      const wallet = await WalletFactory.getWallet(chainType);

      const { message, signature } = await wallet.signin();

      showGlobalLoading();

      const res: any = await signIn({
        message: message,
        signature,
        chainType: 'evm',
        client: 'MetaMask',
        key: wallet.address!
      });

      if (res?.logged) await fetchUser();

      hideGlobalLoading();
    } catch (error: any) {
      if (error.name !== 'UserRejectedRequestError') {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'error'
        });
      }

      throw error;
    } finally {
      hideGlobalLoading();
    }
  };

  return (
    <div className="z-10 border-b border-gray-200 ">
      <div className="container flex h-[88px] items-center justify-between">
        <div className="flex items-center">
          <Link to="/">
            <Logo />
          </Link>
        </div>

        <div className="flex items-center gap-[12px]">
          <div className="flex h-[42px] items-center gap-[8px] rounded-md border border-gray-100 px-[14px] shadow-xs">
            {username ? (
              <>
                <MetaMaskIcon />
                <span className="font-medium text-sm">{username}</span>
              </>
            ) : (
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleConnect(ChainType.EVM);
                }}
              >
                Connect Wallet
              </div>
            )}
          </div>

          <Dropdown
            items={[
              {
                label: 'Sign out',
                icon: <LogOut02 size={20} color="#101828" />,
                onClick: handleLogOut
              }
            ]}
          >
            <div className="rounded-md border border-gray-100 p-[8px] shadow-xs">
              <DotsVertical
                color="#344054"
                size={24}
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
              />
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
