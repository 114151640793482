import { AlertTriangle, CheckCircle, InfoCircle } from '@ethsign/icons';
import { Button, Modal } from '@ethsign/ui';
import cls from 'classnames';
import React, { useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import { LoadingCircle } from './LoadingCircle';

interface ResultModalProps {
  open: boolean;
  onOpenChange: (visible: boolean) => void;
  type: 'success' | 'failed' | 'warning';
  title: React.ReactNode;
  content: React.ReactNode;
  actionText?: string;
  onAction?: (props: { hide: () => void }) => void;
}

const ICON_TYPE_MAP = {
  success: {
    color: ['#039855', 'border-slime-50 bg-slime-100'],
    icon: CheckCircle
  },
  failed: {
    color: ['#D92D20', 'border-red-50 bg-red-100'],
    icon: InfoCircle
  },
  warning: {
    color: ['#DD8D58', 'border-yellow-50 bg-yellow-100'],
    icon: AlertTriangle
  }
};

export const ResultModal: React.FC<ResultModalProps> = (props) => {
  const { type, content, title, actionText, onAction, open, onOpenChange } = props;

  const iconConfig = useMemo(() => ICON_TYPE_MAP[type], [type]);

  return (
    <Modal open={open} onOpenChange={onOpenChange} footer={false} className="!min-w-[343px] !gap-y-0 overflow-hidden">
      <div className="flex items-center justify-center">
        <div
          className={cls('flex size-16 items-center justify-center rounded-full border-[10px]', iconConfig.color[1])}
        >
          <iconConfig.icon color={iconConfig.color[0]} size={32} />
        </div>
      </div>

      <strong className="mt-4 text-center text-[19px] leading-7">{title}</strong>

      <p className="mt-2 text-center text-[15px] font-normal leading-5 text-gray-500">{content}</p>

      <Button
        variant="ghost"
        className="mt-6 text-primary"
        onClick={() => {
          onAction?.({
            hide: () => {
              onOpenChange?.(false);
            }
          });
        }}
      >
        {actionText}
      </Button>
    </Modal>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const showResultModal = (props: Omit<ResultModalProps, 'open' | 'onOpenChange'>) => {
  const { ...restProps } = props;

  return new Promise((resolve) => {
    const modalRoot = document.createElement('div');
    document.body.appendChild(modalRoot);

    const root = createRoot(modalRoot);

    const onClose = () => {
      root.unmount();
      resolve(null);
    };

    const modalComponent = (
      <ResultModal
        open={true}
        onOpenChange={(visible) => {
          if (!visible) {
            onClose();
          }
        }}
        {...restProps}
      />
    );

    root.render(modalComponent);
  });
};

// eslint-disable-next-line react-refresh/only-export-components
export const showProgressModal = (props: { title: string; content: string }) => {
  const { title, content } = props;

  const modalRoot = document.createElement('div');
  document.body.appendChild(modalRoot);

  const root = createRoot(modalRoot);

  const onClose = () => {
    root.unmount();
  };

  const modalComponent = (
    <Modal
      className="!w-[343px]"
      open={true}
      onOpenChange={(visible) => {
        if (!visible) onClose();
      }}
      footer={false}
      maskClosable={false}
      hiddenCloseIcon
    >
      <div className="mb-4 flex justify-center">
        <LoadingCircle />
      </div>

      <h3 className="mb-2 text-center text-lg font-semibold text-gray-900">{title}</h3>

      <p className="text-center text-sm font-normal text-gray-500">{content}</p>
    </Modal>
  );

  root.render(modalComponent);

  return {
    close: onClose
  };
};
