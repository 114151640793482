import { ScrollArea } from '@ethsign/ui';
import { Outlet } from 'react-router-dom';
import './App.css';
import { Header } from './components/Header';

function App() {
  return (
    <div>
      <Header />
      <ScrollArea className="h-[calc(100vh-88px)] bg-[#f7f7f7]">
        <Outlet />
      </ScrollArea>
    </div>
  );
}

export default App;
