import { useCurrentUser } from '@/components/UserProvider';
import { OffchainBalanceItem, OnChainBalanceItem } from '@/types/proof';
import React, { useEffect, useState } from 'react';
import { ChainType } from '../..';
import { ButtonSelect } from '../../../../components/base/ButtonSelect';
import { Events, eventBus } from '../../PageCtrl';
import { AddAssetButton } from './AddAssetButton';
import { OffchainView } from './OffchainView';
import { OnchainView } from './OnchainView';

type AssetsViewProps = {
  onchainBalanceList: OnChainBalanceItem[];
  offchainBalanceList: OffchainBalanceItem[];
};

export const AssetsView: React.FC<AssetsViewProps> = (props: AssetsViewProps) => {
  const { onchainBalanceList: balanceList, offchainBalanceList } = props;

  const [tab, setTab] = useState<ChainType>('onChain');

  const { user } = useCurrentUser();

  useEffect(() => {
    const onOnchainAssetAdded = () => setTab('onChain');
    const onOffchainAssetAdded = () => setTab('offChain');

    eventBus.on(Events.OFFCHAIN_ASSET_ADDED, onOffchainAssetAdded);
    eventBus.on(Events.ONCHAIN_ASSET_ADDED, onOnchainAssetAdded);

    return () => {
      eventBus.off(Events.OFFCHAIN_ASSET_ADDED, onOffchainAssetAdded);
      eventBus.off(Events.ONCHAIN_ASSET_ADDED, onOnchainAssetAdded);
    };
  }, []);

  return (
    <div className="space-y-6 pt-6">
      <ButtonSelect<ChainType>
        value={tab}
        options={[
          {
            label: 'Onchain Assets',
            value: 'onChain',
            icon: 'https://sign-public-cdn.s3.us-east-1.amazonaws.com/PoF/tab-icon-onchain_240724083143.webp',
            description: (
              <>
                Assets from all your wallet addresses.
                <br /> we currently accept only Ethereum, USDC, and Tether.
              </>
            ),
            extra: user?.userId ? ({ active }) => <AddAssetButton type="onchain" active={active} /> : null
          },
          {
            label: 'Offchain Assets',
            value: 'offChain',
            description: (
              <>
                Assets from your bank account.
                <br /> Powered by Plaid.
              </>
            ),
            icon: 'https://sign-public-cdn.s3.us-east-1.amazonaws.com/PoF/tab-icon-offchain_240724083143.webp',
            extra: user?.userId ? ({ active }) => <AddAssetButton type="offchain" active={active} /> : null
          }
        ]}
        onChange={(value) => {
          setTab(value);
        }}
      />

      {tab === 'onChain' && <OnchainView data={balanceList} />}

      {tab === 'offChain' && <OffchainView data={offchainBalanceList} />}
    </div>
  );
};
