import { hideGlobalLoading, showGlobalLoading } from '@/components/Loading';
import { attestReport, getReportDetail } from '@/services/proof';
import { ReportDetail } from '@/types/proof';
import { ControllerBase } from '@/utils/bizify';

export type PageData = {
  loading: boolean;
  reportDetailData: ReportDetail;
  offchainTableData?: any[];
  onchainTableData?: any[];
  editReportNameModalOpen: boolean;
  attestReportModalOpen: boolean;
};

export class PageCtrl extends ControllerBase<PageData> {
  protected $data(): PageData {
    return {
      loading: false,
      editReportNameModalOpen: false,
      attestReportModalOpen: false,
      reportDetailData: {} as ReportDetail
    };
  }
  async getData(id: string) {
    showGlobalLoading();
    try {
      const res = await getReportDetail(id);
      this.state.reportDetailData = res || {};
      hideGlobalLoading();
    } catch (error) {
      hideGlobalLoading();
      console.error(error);
    }
  }

  setData(data: Partial<PageData>) {
    Object.assign(this.state, data);
  }
}
