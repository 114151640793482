import { goerli, mainnet } from '@starknet-react/chains';
import {
  argent,
  braavos,
  publicProvider,
  StarknetConfig,
  starkscan,
  useInjectedConnectors,
  Connector, useAccount, useConnect
} from '@starknet-react/core';
import { Button, Modal } from '@ethsign/ui';
import { DialogHeader } from '@ethsign/ui/dist/Modal/dialog';
import { WalletBase } from '../../WalletBase';
import { useEffect, useState } from 'react';
import { EventEmitter } from 'events';
import { proxy } from 'valtio';
import { prepareSignMessage } from '../../utils';
import { toHex } from 'viem';
import { ISignResult } from '../../types';

const eventBus = new EventEmitter();


interface StarkStoreType {
  account: ReturnType<typeof useAccount> | null;
}


const starknetStore = proxy<StarkStoreType>({
  account: null,
});

const STARK_EVENT_KEY = 'starknet_signin';


export class StarknetWallet extends WalletBase {
  private eventKey = STARK_EVENT_KEY;
  static connectModal: { open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>> };
  connect() {
    StarknetWallet.connectModal.setOpen(true);
  }
  async sign(_message: string) {
    const account = starknetStore.account;
    const res = await account?.account?.signMessage(JSON.parse(_message));

    return { message: _message, signature: (res as string[]).join(',') };
  }

  async signin(statement?: string): Promise<ISignResult> {
    return new Promise((resolve) => {
      const signCallback = (data: ReturnType<typeof useAccount>) => {
        this.address = data.address;
        this.isConnected = true;
        const hexChainId = toHex(data.chainId!);
        this.chainId = hexChainId;
        const msg = prepareSignMessage({
          statement,
          chainId: '',
          address: data!.address!,
        });
        const fullMessage = {
          domain: {
            chainId: hexChainId,
            name: statement,
            version: '1'
          },
          message: msg,
          primaryType: 'Sign',
          types: {
            StarkNetDomain: [
              { name: 'name', type: 'string' },
              { name: 'version', type: 'felt' },
              { name: 'chainId', type: 'felt' }
            ],
            Sign: [
              { name: 'statement', type: 'felt' },
              { name: 'chainId', type: 'felt' },
              { name: 'address', type: 'felt' },
              { name: 'issuedAt', type: 'felt' },
              { name: 'version', type: 'felt' },
              { name: 'nonce', type: 'felt' }
            ]
          }
        };
        const res = this.sign(JSON.stringify(fullMessage));
        resolve(res);
      }
      const account = starknetStore.account;
      if (account?.address) {
        signCallback(account);
        return;
      }
      this.connect();
      eventBus.once(this.eventKey, (data) => {
        console.log('listen event key success, event key = %s, data = %j', this.eventKey, data);
        signCallback(data);
      });
    })
  }
}

function ConnectStarkNetModal() {
  const { connect, connectors } = useConnect();
  const account = useAccount();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (account) {
      starknetStore.account = account;
      eventBus.emit(STARK_EVENT_KEY, account);
    }
  }, [account]);

  StarknetWallet.connectModal = { open, setOpen };
  return (
    <Modal footer={false} open={open} onOpenChange={setOpen}>
      <DialogHeader>Connect StarkNet Wallet</DialogHeader>
      <div className="flex flex-col gap-4">
        {connectors.map((connector: Connector) => (
          <Button
            variant={'outline'}
            key={connector.id}
            onClick={() => (connect({ connector }))}
            disabled={!connector.available()}
          >
            <img src={connector.icon.dark} className="w-4 h-4 mr-2" />
            Connect {connector.name}
          </Button>
        ))}
      </div>
    </Modal>
  );
}

export function StarknetProvider({ children }: { children: React.ReactNode }) {
  const { connectors } = useInjectedConnectors({
    // Show these connectors if the user has no connector installed.
    recommended: [argent(), braavos()],
    // Hide recommended connectors if the user has any connector installed.
    includeRecommended: 'onlyIfNoConnectors',
    // Randomize the order of the connectors.
    order: 'alphabetical'
  });

  return (
    <StarknetConfig
      autoConnect
      chains={[mainnet, goerli]}
      provider={publicProvider()}
      connectors={connectors}
      explorer={starkscan}
    >
      {children}
      <ConnectStarkNetModal />
    </StarknetConfig>
  );
}