import App from '@/App.tsx';
import NotFound from '@/pages/404';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { DashboardLayout } from './pages/dashboard';
import Preview from './pages/preview';
import Axiom from './pages/axiom';
import Dashboard from './pages/home';
import Report from './pages/report';

const routerConfig = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <DashboardLayout />
      },
      {
        path: '/axiom',
        element: <Axiom />
      },
      {
        path: '/dashboard',
        element: <Dashboard />
      },
      {
        path: '/report/:id',
        element: <Report />
      }
    ]
  },
  {
    path: '/preview/:id',
    element: <Preview />
  },
  {
    path: '*',
    element: <NotFound />
  }
];

const router = createBrowserRouter(routerConfig);

export const Router = () => {
  return <RouterProvider router={router} />;
};
