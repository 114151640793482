import { Navigate, Outlet } from "react-router-dom"
import { SideNav } from "./components/SideNav"


export const DashboardLayout = () => {
    return <Navigate to="/dashboard" replace={true} />
}


export const Dashboard = () => {
    return (
        <div className="flex w-full h-[calc(100vh-60px)]">
            <SideNav />
            <div className="flex-1">
                <Outlet />
            </div>
        </div>
    )
}