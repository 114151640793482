import { ChevronDown } from '@ethsign/icons';
import { Dropdown } from '@ethsign/ui';
import cls from 'classnames';
import React, { PropsWithChildren, useMemo, useState } from 'react';

export interface ButtonRadioOption {
  label: string;
  value: string;
  tag?: string;
}

export interface ButtonRadioProps {
  className?: string;
  value: string;
  options?: ButtonRadioOption[];
  onChange: (value: string) => void;
}

export const ButtonRadioItem: React.FC<
  PropsWithChildren<{
    className?: string;
    active?: boolean;
    onClick?: () => void;
    tag?: string;
    label?: string;
  }>
> = (props) => {
  const { className, active, tag, label, children, onClick } = props;

  return (
    <div
      className={cls(
        'flex min-w-16 cursor-pointer items-center justify-center gap-2 px-4 py-3 font-semiBold text-[15px] leading-5 hover:bg-gray-50 relative border-r border-solid border-r-gray-300 last:border-none',
        { 'bg-gray-50': active },
        className
      )}
      onClick={onClick}
    >
      {children ? (
        children
      ) : (
        <>
          <span>{label}</span>
          {tag && (
            <span className="flex items-center justify-center rounded-sm bg-[#ECF2FF] px-2 py-1 font-semiBold text-[13px] leading-[18px] text-primary">
              {tag}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export const ButtonRadio: React.FC<ButtonRadioProps> = (props) => {
  const { className, onChange, options = [], value } = props;

  const maxVisibleCount = 7;

  const visibleOptions = useMemo(() => options.slice(0, maxVisibleCount), [options]);

  const remainOptions = useMemo(() => options.slice(maxVisibleCount, options.length), [options]);

  const [selectedOptionInDropdown, setSelectedOptionInDropdown] = useState<ButtonRadioOption | null>(null);

  return (
    <div
      className={cls(
        'overflow-hidden rounded-lg border border-solid border-gray-300 bg-white shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] flex w-max',
        className
      )}
    >
      {visibleOptions.map((option, index) => (
        <ButtonRadioItem
          key={index}
          active={value === option.value}
          label={option.label ?? option.value}
          tag={option.tag}
          onClick={() => {
            setSelectedOptionInDropdown(null);
            onChange?.(option.value);
          }}
        />
      ))}

      {remainOptions.length > 0 && (
        <ButtonRadioItem className="!p-0" active={selectedOptionInDropdown !== null}>
          <Dropdown
            items={remainOptions.map((item) => ({
              label: item.label,
              icon: null,
              disabled: selectedOptionInDropdown?.value === item.value,
              onClick: () => {
                setSelectedOptionInDropdown(item);
                onChange?.(item.value);
              }
            }))}
          >
            <div className="flex size-full min-w-max items-center justify-center gap-2 px-4 py-3">
              {selectedOptionInDropdown ? (
                selectedOptionInDropdown.label
              ) : (
                <span> +{remainOptions.length} more addresses</span>
              )}

              <ChevronDown />
            </div>
          </Dropdown>
        </ButtonRadioItem>
      )}
    </div>
  );
};
