import { Checkbox } from '@ethsign/ui';
import clsx from 'clsx';
import { ReactElement } from 'react';
type Item = {
  value: string;
  label: string | ReactElement;
  checked?: boolean;
  [key: string]: any;
};
type Props = {
  className?: string;
  data: Item[];
  onChange: (value: Item[]) => void;
  itemRenderer?: (item: Item) => ReactElement;
};

export const CheckboxMultiple = (props: Props) => {
  const { data, onChange, itemRenderer, className } = props;
  return (
    <div className={`flex flex-col gap-4 ${className}`}>
      {data.map((item, i) => (
        <div
          className={clsx('flex cursor-pointer items-center gap-2 rounded-lg border px-4', {
            'bg-secondary-50 border-primary': item.checked,
            'border-gray-300': !item.checked
          })}
          key={i}
          onClick={() => {
            const newData = data.map((d) => {
              if (d.value === item.value) return { ...d, checked: !item.checked };
              return d;
            });

            onChange(newData as Item[]);
          }}
        >
          <Checkbox
            className="data-[state=unchecked]:!border-gray-400"
            key={item.value}
            checked={item.checked}
            onCheckedChange={(checked) => {
              const newData = data.map((d) => {
                if (d.value === item.value) {
                  return { ...d, checked };
                }
                return d;
              });

              onChange(newData as Item[]);
            }}
          ></Checkbox>
          {itemRenderer ? itemRenderer(item) : item.label}
        </div>
      ))}
    </div>
  );
};
