import cls from 'classnames';
import React from 'react';

export interface ButtonSelectProps<T extends string | number> {
  options: {
    label: string;
    value: T;
    icon?: string;
    description?: React.ReactNode;
    extra?: React.ReactNode | ((context: { active: boolean }) => React.ReactNode);
  }[];
  value: T;
  onChange: (value: T) => void;
  className?: string;
}

export const ButtonSelect = <T extends string | number>(props: ButtonSelectProps<T>) => {
  const { options, value, className, onChange } = props;

  return (
    <div className={cls('flex items-center gap-4', className)}>
      {options.map((option, index) => (
        <div
          key={index}
          onClick={() => {
            onChange?.(option.value);
          }}
          className={cls(
            'h-auto flex-1 rounded-md !p-5 hover:bg-secondary-50 hover:text-secondary-foreground cursor-pointer',
            {
              'bg-secondary text-secondary-foreground': value === option.value,
              'bg-white text-gray-500': value !== option.value
            }
          )}
        >
          <div className="flex items-center">
            {option.icon && (
              <span className="mr-[10px] inline-flex size-10 items-center justify-center overflow-hidden">
                <img
                  src={option.icon}
                  className={cls('size-full object-contain', {
                    '[filter:invert(20%)_sepia(71%)_saturate(5268%)_hue-rotate(221deg)_brightness(100%)_contrast(111%)]':
                      value === option.value
                  })}
                  crossOrigin="anonymous"
                />
              </span>
            )}

            <div className="flex flex-1 items-center justify-between">
              <div className="flex flex-1 flex-col">
                <span
                  className={cls('font-bold text-[17px] leading-6 text-black', {
                    'text-secondary-foreground': value === option.value
                  })}
                >
                  {option.label}
                </span>
                {option.description && (
                  <div className="text-xs font-normal leading-[18px] text-gray-500">{option.description}</div>
                )}
              </div>

              {option.extra !== undefined && (
                <div className="w-min">
                  {typeof option.extra === 'function' ? option.extra({ active: value === option.value }) : option.extra}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
