import { ApiRequestOptions, apiClient } from '@/utils/api-client';

///sa/signin
export const signIn = async (data: {
  message: string;
  signature: string;
  chainType: string;
  client: string;
  key: string;
}) => {
  return apiClient.post(`/signin`, data);
};

///sa/signout
export const signOut = async () => {
  return apiClient.post(`/signout`);
};

///sa/my/info
export const getMyInfo = async (options?: ApiRequestOptions) => {
  return apiClient.get<any>(`/my/info`, options);
};

export const plaidPrepare = async () => {
  return apiClient.post<any>(`/pof/plaid-prepare`);
};

export const plaidAuth = async (publicToken: string) => {
  return apiClient.post<any>(`/pof/plaid-auth`, { publicToken });
};

export const getMyBalance = async (publicToken: string) => {
  return apiClient.get<any>(`/pof/balance?token=${publicToken}`);
};

export async function prepareSingpass(): Promise<any> {
  return apiClient.post('/my/prepare-singpass');
}

export async function connectSingpass(data: { code: string }): Promise<any> {
  return apiClient.post('/my/connect-singpass', data);
}

export async function disconnectSingpass(): Promise<any> {
  return apiClient.post('/my/disconnect-singpass');
}