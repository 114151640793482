import { sepolia } from 'viem/chains';

export const Constants: any = Object.freeze({
  [sepolia.id]: {
    EXPLORER_BASE_URL: 'https://explorer.axiom.xyz/v2/sepolia',
    CALLBACK_CONTRACT: '0x50F2D5c9a4A35cb922a631019287881f56A00ED5',
    EXAMPLE_CLIENT_ADDRESS: '0x4A4e2D8f3fBb3525aD61db7Fc843c9bf097c362e',
    CHAIN_ID_SEPOLIA: sepolia.id
  }
});
