import compiledCircuit from './axiom/data/compiled.json';
import { AxiomCircuitProvider } from '@axiom-crypto/react';
import { useEffect, useState } from 'react';
import { sepolia } from 'viem/chains';

export default function AxiomProvider({ children }: { children: React.ReactNode }) {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  return (
    <AxiomCircuitProvider
      compiledCircuit={compiledCircuit}
      provider={'https://sepolia.infura.io/v3/264d5632b46f4c3c906ae171aee9163f'}
      chainId={sepolia.id}
    >
      {mounted && children}
    </AxiomCircuitProvider>
  );
}
