import LoadingAnimation from '@/components/LoadingAnimation';
import { useAxiomCircuit } from '@axiom-crypto/react';
import { useEffect } from 'react';
import jsonInputs from '../../axiom/data/inputs.json';
import SubmitQuery from './SubmitQuery';

export default function BuildQuery({
  inputs,
  callbackTarget,
  callbackExtraData,
  refundee,
  callbackAbi
}:
  {
    inputs: any;
    callbackTarget: string;
    callbackExtraData: string;
    refundee: string;
    callbackAbi: any[];
  }) {
  const { build, builtQuery, setParams, areParamsSet } = useAxiomCircuit<typeof jsonInputs>();

  useEffect(() => {
    setParams(inputs, callbackTarget, callbackExtraData, refundee);
  }, [inputs, callbackTarget, callbackExtraData, refundee, setParams]);

  useEffect(() => {
    const buildQuery = async () => {
      try {
        console.log('areParamsSet', areParamsSet);
        if (!areParamsSet) {
          return;
        }
        await build();
        console.log('build 了');
      } catch (error) {
        console.error('error', error);
      }
    };
    buildQuery();
  }, [build, areParamsSet]);

  if (!builtQuery) {
    return (
      <div className="flex flex-row items-center gap-2">
        {'Building Query'} <LoadingAnimation />
      </div>
    );
  }

  return <SubmitQuery callbackAbi={callbackAbi} />;
}
