import React from 'react';
import { Table as TableCore, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@ethsign/ui';

type ColumnType = {
  title: string;
  dataIndex: string;
  className?: string;
  render?: (record: any) => React.ReactNode;
};

type DataTableProps = {
  columns: ColumnType[];
  dataSource?: any[];
};

export const Table: React.FC<DataTableProps> = ({ columns, dataSource }) => {
  return (
    <TableCore>
      <TableHeader>
        <TableRow>
          {columns.map((column) => (
            <TableHead key={column.title} className={`bg-gray-50 text-xs text-gray-500 ${column.className}`}>
              {column.title}
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody className="text-black">
        {dataSource?.length ? (
          dataSource?.map((record, index) => (
            <TableRow key={index}>
              {columns.map((column) => (
                <TableCell key={column.dataIndex} className={`font-medium text-sm text-black ${column.className}`}>
                  {column.render ? column.render(record) : record[column.dataIndex]}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center text-sm text-gray-400 opacity-60">
              <div className="flex w-full items-center justify-center">{'No results.'}</div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </TableCore>
  );
};
