'use client';
import { Eye, EyeOff } from '@ethsign/icons';
import { Input } from '@ethsign/ui';
import { InputProps } from '@ethsign/ui/dist/Input';
import React from 'react';

export const PasswordInput = (props: InputProps) => {
  const [type, setType] = React.useState('password' as string);
  return (
    <Input
      className="focus:border-primary"
      autoComplete={'off'}
      placeholder={'Enter Password'}
      type={type}
      maxLength={20}
      suffix={
        type === 'password' ? (
          <EyeOff className={'cursor-pointer'} size={16} color={'#98A2B3'} onClick={() => setType('text')} />
        ) : (
          <Eye className={'cursor-pointer'} size={16} color={'#98A2B3'} onClick={() => setType('password')} />
        )
      }
      {...props}
    />
  );
};
