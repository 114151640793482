import { ReportItem } from '@/types/proof';
import { File05 } from '@ethsign/icons';
import { ReportCard } from './ReportCard';

type Props = {
  data?: ReportItem[];
};

export const ReportsView = (props: Props) => {
  const { data } = props;
  const hasData = data && data?.length > 0;
  return (
    <div className="pt-6">
      {hasData ? (
        <div className="flex flex-wrap gap-8">
          {data.map((item, i) => (
            <ReportCard key={i} {...item} />
          ))}
        </div>
      ) : (
        <div className="rounded-[20px] bg-white">
          <div className="mx-auto w-[416px] space-y-4 px-6 py-8">
            <div className="flex items-center justify-center">
              <div className="flex size-12 items-center justify-center rounded-full bg-secondary">
                <File05 color="#0052FF" size={24} />
              </div>
            </div>

            <div className="text-center">
              <strong className="font-bold text-[17px] leading-6">No Report</strong>
              <p className="text-[15px] font-normal leading-5 text-gray-500">There is no reports generated.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
