import { InfoCircle } from '@ethsign/icons';
import { Button, Modal } from '@ethsign/ui';
import React from 'react';

export const CoinTypeModal: React.FC<{ open: boolean; onOpenChange: (visible: boolean) => void }> = (props) => {
  const { onOpenChange, open } = props;

  return (
    <Modal className="!w-[400px] !gap-y-0" open={open} footer={false} onOpenChange={onOpenChange}>
      <div className="flex items-center justify-center">
        <div className="flex size-16 items-center justify-center rounded-full border-[10px] border-[#F8FAFF] bg-[#ECF2FF]">
          <InfoCircle color="hsl(var(--primary))" size={32} />
        </div>
      </div>

      <strong className="mt-4 text-center text-[19px] leading-7">Supported Coin Type</strong>

      <p className="mt-2 text-center text-[15px] font-normal leading-5 text-gray-500">
        Given the inherent volatility of the cryptocurrency market, for the purpose of providing proof of funds, we
        currently accept only stablecoins: Ethereum, USDC, and Tether.
      </p>

      <Button variant="ghost" className="mt-6 text-primary">
        OK
      </Button>
    </Modal>
  );
};
