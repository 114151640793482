import { getMyInfo } from '@/services';
import { useQuery } from '@tanstack/react-query';
import { createContext, useContext } from 'react';

interface UserInfoContextProps {
  user?: { userId: string; client: string };
  isLoading: boolean;
  fetchUser: () => void;
}

const UserInfoContext = createContext<UserInfoContextProps>({
  user: undefined,
  isLoading: false,
  fetchUser: () => {}
});

export const UserProvider = ({ children }: { children: any }) => {
  const {
    data: user,
    isLoading,
    refetch
  } = useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      let res;
      try {
        res = await getMyInfo({ skipHandleError: true });
      } catch (error) {
        res = {};
        console.error(error);
      }
      return res;
    }
  });

  return (
    <UserInfoContext.Provider value={{ user, isLoading, fetchUser: refetch }}>{children}</UserInfoContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useCurrentUser = () => {
  return useContext(UserInfoContext);
};
