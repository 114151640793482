import EventEmitter from 'events';
import { customAlphabet } from 'nanoid';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import querystring from 'querystring';
import crypto from 'crypto';

import { chainInfo } from '@ethsign/sp-sdk';
import { createPublicClient, http } from 'viem';
import { mainnet } from 'viem/chains';

dayjs.extend(advancedFormat);
const formatTypeToString: any = {
  datetime: 'MMMM DD, YYYY HH:mm:ss',
  date: 'MMMM DD, YYYY',
  time: 'HH:mm:ss'
};
export const dateFormat = (
  date: string | number | dayjs.Dayjs | Date | null | undefined,
  format?: 'datetime' | 'date' | 'time' | string
): string => {
  return dayjs(date).format(formatTypeToString[format || 'date'] || format);
};

export const getCustomNaNoId = (): string => {
  const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 10);
  return nanoid();
};

export const eventBus = new EventEmitter();

export function preHash(timestamp: string, method: string, request_path: string, params: any) {
  // 根据字符串和参数创建预签名
  let query_string = '';
  if (method === 'GET' && params) {
    query_string = '?' + querystring.stringify(params);
  }
  if (method === 'POST' && params) {
    query_string = JSON.stringify(params);
  }
  return timestamp + method + request_path + query_string;
}

function sign(message: string, secret_key: string) {
  // 使用 HMAC-SHA256 对预签名字符串进行签名
  const hmac = crypto.createHmac('sha256', secret_key);
  hmac.update(message);
  return hmac.digest('base64');
}

export function createSignature(method: string, request_path: string, params: any, secret_key: string) {
  // 获取 ISO 8601 格式时间戳
  const timestamp = new Date().toISOString().slice(0, -5) + 'Z';
  // 生成签名
  const message = preHash(timestamp, method, request_path, params);
  const signature = sign(message, secret_key);
  return { signature, timestamp };
}

export const getChainInfo = (chainId: string) => {
  return chainInfo.evm.find((chain) => chain.id === chainId);
};

export const handleAmount = (amount: string | number, fixed = 2) => {
  return Number(amount || 0).toFixed(fixed);
};

export const formatBalance = (value: number, currency: string) => [value, currency].join(' ');

export const getEnsNameByContract = async (address: `0x${string}`): Promise<string | null> => {
  const publicClient = getPublicClient();
  return await publicClient.getEnsName({
    address
  });
};
const getPublicClient = () => {
  const publicClient = createPublicClient({
    chain: mainnet,
    transport: http('https://eth.llamarpc.com')
  });
  return publicClient;
};
