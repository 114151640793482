import { Table } from '@/components/Table';
import { useCurrentUser } from '@/components/UserProvider';
import { useViewModel } from '@/utils/bizify';
import { dateFormat, getEnsNameByContract, handleAmount } from '@/utils/common';
import { outputPDF } from '@/utils/pdf-print';
import { ChevronLeft, Download01 } from '@ethsign/icons';
import { Button } from '@ethsign/ui';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageCtrl } from './PageCtrl';

export default function Report() {
  const { id } = useParams<{ id: string }>();
  const vm = useViewModel(PageCtrl);
  const vmData = vm.$useSnapshot();
  const { user } = useCurrentUser();

  useEffect(() => {
    id && vm.getData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const ref = useRef(null);
  const reportData = vmData.reportDetailData?.reportData || {};
  const [ensName, setEnsName] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (user?.userId) {
      getEnsNameByContract(user.userId as any).then((res) => {
        setEnsName(res || '');
      });
    }
  }, [user?.userId]);

  return (
    <div className="flex h-screen flex-col overflow-hidden">
      <div className="flex h-[88px] justify-center border-b border-gray-200">
        <div className="flex max-w-[1190px] flex-1 items-center justify-between">
          <Button
            variant="outline"
            className="shadow-[0px_1px_2px_0px_rgba(16_24,_40,_0.05)]"
            onClick={() => {
              navigate('/dashboard?view=report');
            }}
          >
            <ChevronLeft size={20} />
            <span className="ml-2">Back</span>
          </Button>

          <Button
            className="flex items-center gap-2"
            onClick={async () => {
              await outputPDF({ element: ref.current, filename: vmData.reportDetailData.name });
            }}
          >
            <Download01 size={20} color="white" /> Download
          </Button>
        </div>
      </div>
      <div className="flex-1 overflow-auto">
        <div className="flex min-h-screen w-full flex-col items-center overscroll-auto bg-[rgba(52,64,84,0.7)]">
          <div className="my-10 w-[1190px] bg-gray-50" ref={ref}>
            <div className="flex items-center justify-between bg-secondary-foreground px-[83px] py-6 font-semiBold text-dxs text-white">
              Account Statement
            </div>

            <div className="px-[83px] py-10">
              <div className="flex items-stretch justify-between gap-4">
                <div className="relative flex-1 overflow-hidden rounded-[48px] shadow-[0px_4px_16px_0px_rgba(25,33,61,0.04),0px_2px_6px_0px_rgba(25,33,61,0.05)]">
                  <img
                    className="absolute left-0 top-0 size-full select-none object-cover"
                    crossOrigin="anonymous"
                    src="https://sign-public-cdn.s3.us-east-1.amazonaws.com/PoF/report-bg_240724084732.webp"
                  />

                  <div className="relative z-10 space-y-4 px-20 py-[50px]">
                    <img
                      className="w-[287px] object-contain"
                      crossOrigin="anonymous"
                      src="https://sign-public-cdn.s3.us-east-1.amazonaws.com/PoF/logo-pof_240813061733.svg"
                    />

                    <div className="space-y-1">
                      <div className="text-md font-normal text-gray-500">Issued:</div>
                      <div className="text-lg font-semibold text-black">
                        {dateFormat(Number(vmData.reportDetailData?.reportDate), 'datetime')}
                      </div>
                    </div>

                    <div className="space-y-1">
                      <div className="text-md font-normal text-gray-500">Attestation ID:</div>
                      <div className="text-lg font-semibold text-black">{vmData.reportDetailData.attestationId}</div>
                    </div>
                  </div>
                </div>

                <div className="w-[373px] space-y-6 overflow-hidden rounded-[48px] bg-white p-8 shadow-[0px_4px_16px_0px_rgba(25,33,61,0.04),0px_2px_6px_0px_rgba(25,33,61,0.05)]">
                  <div className="space-y-1 rounded-3xl bg-gray-50 p-4">
                    <div className="text-md font-normal text-gray-500">Wallet Address</div>
                    <div className="break-all font-semiBold text-md text-black">{user?.userId}</div>
                  </div>

                  <div className="flex flex-col items-end gap-2 font-semiBold">
                    <div className="text-xl font-semibold text-black">Total amount:</div>
                    <div className="text-[38px]/[50px] font-semibold text-black">
                      {handleAmount(reportData?.totalValue)}
                    </div>
                    <div className="font-semiBold text-dxs text-gray-500">$ USD</div>
                  </div>
                </div>
              </div>

              <div className="mt-16 space-y-2">
                <div className="font-semiBold text-dxs text-black">Asset Summary</div>
                <div className="text-sm text-gray-600">
                  Summary of all your assets and liabilities as on{' '}
                  {dateFormat(Number(vmData.reportDetailData?.reportDate), 'datetime')}
                </div>
              </div>

              <div className="mt-6 flex flex-col gap-8">
                {reportData?.bankResult && (
                  <div className="rounded-t-[10px] bg-shareBackground ">
                    <div className="inline-block w-[261px] rounded-t-xl bg-[rgba(0,_82,_255,_0.1)] p-4 text-center font-semiBold text-md text-secondary-foreground">
                      Bank Accounts Balance
                    </div>

                    <div className="rounded-r-2xl  border  border-gray-200 bg-white">
                      <div className="flex items-center justify-between border-b border-gray-200 p-6">
                        <div className="font-semiBold text-lg text-gray-500 ">
                          Total Balance:{' '}
                          <span className="text-gray-900">${handleAmount(reportData?.bankResult?.totalValue)}</span>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-gray-500">
                          Provided by
                          <img
                            className="h-6 w-[59px]"
                            crossOrigin="anonymous"
                            src="https://sign-public-cdn.s3.us-east-1.amazonaws.com/PoF/provider-plaid_240724084140.webp"
                          />
                        </div>
                      </div>

                      <Table
                        columns={[
                          {
                            title: 'Account',
                            dataIndex: 'accountId'
                          },
                          {
                            title: 'Account Type',
                            dataIndex: 'subtype'
                          },
                          {
                            title: 'Available Balance',
                            dataIndex: 'availableBalance'
                          }
                        ]}
                        dataSource={reportData?.bankResult.assets as any}
                      />
                    </div>
                  </div>
                )}

                {reportData?.onchainResult && (
                  <div className=" rounded-t-[10px] bg-shareBackground">
                    <div className="inline-block w-[261px] rounded-t-xl bg-[rgba(0,_82,_255,_0.1)] p-4 text-center font-semiBold text-md text-secondary-foreground">
                      Onchain Assets
                    </div>
                    <div className="rounded-r-2xl  border  border-gray-200 bg-white">
                      <div className="flex items-center justify-between border-b border-gray-200 p-6">
                        <div className="font-semiBold text-lg text-gray-500 ">
                          Total Balance:{' '}
                          <span className="text-gray-900">${handleAmount(reportData?.onchainResult?.totalValue)}</span>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-gray-500">
                          Provided by
                          <img
                            className="h-6 w-[91px]"
                            crossOrigin="anonymous"
                            src="https://sign-public-cdn.s3.us-east-1.amazonaws.com/PoF/provider-axiom_240724084140.webp"
                          />
                        </div>
                      </div>
                      <Table
                        columns={[
                          {
                            title: 'Network',
                            dataIndex: 'network'
                          },
                          {
                            title: 'Address',
                            dataIndex: 'address'
                          },
                          {
                            title: 'Balance',
                            dataIndex: 'balance',
                            render: (data) => {
                              return handleAmount(data.balance, 4);
                            }
                          },
                          {
                            title: 'Token',
                            dataIndex: 'token'
                          },
                          {
                            title: 'Value',
                            dataIndex: 'value',
                            render: (data) => {
                              return '$' + handleAmount(data.value);
                            }
                          }
                        ]}
                        dataSource={reportData?.onchainResult.assets.filter((item) => item.value !== '0') as any}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-[136px] flex flex-col gap-4">
                <div className="text-md font-semibold text-gray-900">Disclaimer:</div>
                <div className="text-xs text-gray-500">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua.
                  <span className="text-gray-900">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua.
                  </span>
                </div>
                <div className="font-medium text-sm text-gray-900">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
