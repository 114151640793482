import { hideGlobalLoading, showGlobalLoading } from '@/components/Loading';
import { createReport, getOffChainBalanceList, getOnchainBalanceList, getReportList } from '@/services/proof';
import { OffchainBalanceItem, OnChainBalanceItem, ReportAccount, ReportItem } from '@/types/proof';
import { ControllerBase } from '@/utils/bizify';
import { dateFormat } from '@/utils/common';
import EventEmitter from 'events';
import { numberAdd } from '@bizjs/biz-utils';

interface PageData {
  generateReportModalOpen: boolean;
  balanceList: OnChainBalanceItem[];
  offchainBalanceList: OffchainBalanceItem[];
  reportList: ReportItem[];
  totalAmount?: number | string;
}

export const eventBus = new EventEmitter();

export enum Events {
  ONCHAIN_ASSET_DISCONNECT = 'ONCHAIN_ASSET_DISCONNECT',
  OFFCHAIN_ASSET_DISCONNECT = 'OFFCHAIN_ASSET_DISCONNECT',
  ONCHAIN_ASSET_ADDED = 'ONCHAIN_ASSET_ADDED',
  OFFCHAIN_ASSET_ADDED = 'OFFCHAIN_ASSET_ADDED',
  SIGN_OUT = 'SIGN_OUT'
}

export class PageCtrl extends ControllerBase<PageData> {
  private initState: PageData = {
    generateReportModalOpen: false,
    balanceList: [],
    reportList: [],
    offchainBalanceList: [],
    totalAmount: 0
  }

  private disposeEventListeners?: () => void;

  protected $onMounted = () => {
    const disposers: (() => void)[] = []

    function setUpEventListeners(eventName: Events | Events[], handler: () => void) {
      const events = Array.isArray(eventName) ? eventName : [eventName]

      events.forEach(event => eventBus.on(event, handler))

      disposers.push(() => events.forEach(event => eventBus.off(event, handler)))
    }

    const listDataUpdateEvents = [
      Events.ONCHAIN_ASSET_ADDED,
      Events.OFFCHAIN_ASSET_ADDED,
      Events.ONCHAIN_ASSET_DISCONNECT,
      Events.OFFCHAIN_ASSET_DISCONNECT
    ];

    setUpEventListeners(listDataUpdateEvents, this.queryData)

    setUpEventListeners(Events.SIGN_OUT, () => {
      this.setData(this.initState)
    })

    this.disposeEventListeners = () => disposers.forEach(dispose => dispose())
  };

  protected $onUnMounted = () => {
    this.disposeEventListeners?.();
  };

  protected $data(): PageData {
    return this.initState;
  }

  setData(data: Partial<PageData>) {
    Object.assign(this.state, data);
  }

  async queryData() {
    showGlobalLoading();

    try {
      const [balanceList, offchainBalanceList, reportList] = await Promise.all([
        getOnchainBalanceList(),
        getOffChainBalanceList(),
        getReportList()
      ]);

      this.state.balanceList = balanceList.rows;
      this.state.offchainBalanceList = offchainBalanceList.rows.map(item => ({
        ...item,
        institution: {
          ...item.institution,
          logo: item.institution.logo || 'https://sign-public-cdn.s3.us-east-1.amazonaws.com/PoF/tab-icon-offchain_240724082733.webp'
        }
      }));

      this.state.reportList = reportList.rows;
      this.state.totalAmount = numberAdd(balanceList.totalValue, offchainBalanceList.totalValue)

    } finally {
      hideGlobalLoading()
    }
  }

  async generateReport(reportAccout: ReportAccount[]) {
    const res = await createReport({
      accounts: reportAccout,
      reportName: 'Proof of Assets Report - ' + dateFormat(Date.now(), 'datetime')
    });

    return res.reportId;
  }
}
