export const MetaMaskIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.6543 8L21.5527 15.5025L23.4208 11.0761L31.6543 8Z"
        fill="#E2761B"
        stroke="#E2761B"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33545 7.99414L18.3558 15.5677L16.5791 11.0703L8.33545 7.99414Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.0204 25.3838L25.3301 29.5056L31.0864 31.0894L32.7412 25.4752L28.0204 25.3838Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.26904 25.4752L8.91371 31.0894L14.6701 29.5056L11.9797 25.3838L7.26904 25.4752Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3448 18.419L12.7407 20.8454L18.4565 21.0992L18.2534 14.957L14.3448 18.419Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.6451 18.4187L21.6857 14.8857L21.5537 21.0989L27.2593 20.8451L25.6451 18.4187Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6699 29.5055L18.1014 27.8303L15.1369 25.5156L14.6699 29.5055Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8882 27.8303L25.3298 29.5055L24.8527 25.5156L21.8882 27.8303Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3303 29.512L21.8887 27.8369L22.1628 30.0806L22.1323 31.0247L25.3303 29.512Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6699 29.512L17.8679 31.0247L17.8476 30.0806L18.1014 27.8369L14.6699 29.512Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9186 24.039L15.0557 23.1963L17.076 22.2725L17.9186 24.039Z"
        fill="#233447"
        stroke="#233447"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0708 24.039L22.9134 22.2725L24.9439 23.1963L22.0708 24.039Z"
        fill="#233447"
        stroke="#233447"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6698 29.5125L15.1572 25.3906L11.9795 25.482L14.6698 29.5125Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.8418 25.3906L25.3291 29.5125L28.0195 25.482L24.8418 25.3906Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.2583 20.8516L21.5527 21.1054L22.0807 24.0394L22.9233 22.2729L24.9537 23.1967L27.2583 20.8516Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0559 23.1967L17.0864 22.2729L17.9189 24.0394L18.4569 21.1054L12.7412 20.8516L15.0559 23.1967Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7402 20.8516L15.1362 25.5216L15.055 23.1967L12.7402 20.8516Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9536 23.1967L24.8521 25.5216L27.2581 20.8516L24.9536 23.1967Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.456 21.1084L17.918 24.0424L18.588 27.5043L18.7403 22.946L18.456 21.1084Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5524 21.1084L21.2783 22.9358L21.4001 27.5043L22.0804 24.0424L21.5524 21.1084Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0811 24.0399L21.4009 27.5018L21.8882 27.8369L24.8527 25.5221L24.9542 23.1973L22.0811 24.0399Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0557 23.1973L15.1369 25.5221L18.1014 27.8369L18.5887 27.5018L17.9186 24.0399L15.0557 23.1973Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1319 31.0215L22.1623 30.0773L21.9085 29.854H18.0811L17.8476 30.0773L17.8679 31.0215L14.6699 29.5088L15.7867 30.4225L18.0506 31.9961H21.939L24.2131 30.4225L25.3298 29.5088L22.1319 31.0215Z"
        fill="#C0AD9E"
        stroke="#C0AD9E"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8883 27.835L21.401 27.5H18.5888L18.1015 27.835L17.8477 30.0787L18.0812 29.8553H21.9086L22.1624 30.0787L21.8883 27.835Z"
        fill="#161616"
        stroke="#161616"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.0816 15.984L32.9445 11.8419L31.6552 7.99414L21.8887 15.2429L25.645 18.4205L30.9547 19.9738L32.1323 18.6033L31.6247 18.2378L32.4369 17.4967L31.8075 17.0094L32.6196 16.3901L32.0816 15.984Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.05615 11.8419L7.9191 15.984L7.37087 16.3901L8.18306 17.0094L7.56377 17.4967L8.37595 18.2378L7.86833 18.6033L9.03585 19.9738L14.3455 18.4205L18.1018 15.2429L8.33534 7.99414L7.05615 11.8419Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9536 19.9781L25.6439 18.4248L27.2581 20.8512L24.8521 25.5213L28.0196 25.4806H32.7404L30.9536 19.9781Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3452 18.4248L9.03554 19.9781L7.26904 25.4806H11.9797L15.1371 25.5213L12.7411 20.8512L14.3452 18.4248Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5527 21.1037L21.8878 15.2458L23.4309 11.0732H16.5781L18.101 15.2458L18.4563 21.1037L18.5781 22.9514L18.5883 27.4996H21.4005L21.4208 22.9514L21.5527 21.1037Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.693877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
