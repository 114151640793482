import { Router } from '@/Router.tsx';
import { wagmiConfig } from '@/Web3Provider.tsx';
import { ThemeProvider, Toaster } from '@ethsign/ui';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { EvmProvider } from './core/wallet-sdk';
import './index.css';
import { WagmiProvider } from 'wagmi';
import { GlobalLoading } from './components/Loading';
import { UserProvider } from './components/UserProvider';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider defaultTheme={'light'} storageKey={'theme'}>
      <GlobalLoading />
      <EvmProvider config={wagmiConfig}>
        <WagmiProvider config={wagmiConfig}>
          <UserProvider>
            <Router />
          </UserProvider>
        </WagmiProvider>
      </EvmProvider>
      <Toaster />
    </ThemeProvider>
  </React.StrictMode>
);
