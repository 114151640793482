import { dateFormat, getEnsNameByContract, handleAmount } from '@/utils/common';
import { Bank, CoinsStacked03, Edit02 } from '@ethsign/icons';
import { Button } from '@ethsign/ui';

import { useNavigate, useParams } from 'react-router-dom';
import { useViewModel } from '@/utils/bizify';
import { PageCtrl } from './PageCtrl';
import { Table } from '@/components/Table';
import { AttestReportModal } from './components/AttestReportModal';
import { EditReportNameModal } from './components/EditReportNameModal';
import { useAccount } from 'wagmi';
import { useEffect } from 'react';

export default function Report() {
  const { id } = useParams<{ id: string }>();
  const vm = useViewModel(PageCtrl);
  const vmData = vm.$useSnapshot();
  const accout = useAccount();
  const navagate = useNavigate();
  useEffect(() => {
    id && vm.getData(id);
  }, [id]);
  const reportData = vmData.reportDetailData?.reportData || {};

  return (
    <div className="container flex justify-center">
      <div className="w-[1148px] py-8">
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2 font-semiBold text-dsm text-black">
              {vmData.reportDetailData?.name}
              <div
                className="flex size-12 cursor-pointer items-center justify-center rounded-full bg-secondary"
                onClick={() => {
                  vm.setData({ editReportNameModalOpen: true });
                }}
              >
                <Edit02 color="#0052FF" size={24} />
              </div>
            </div>
            <div className="text-sm font-normal text-gray-700">
              Please review your Proof of Asset report, which you can also download.
            </div>
          </div>
          <div className="flex flex-col items-end gap-2">
            <div className="flex items-center gap-2 font-semiBold text-xl text-black">
              Total Assets: ${handleAmount(reportData.totalValue || 0)}
            </div>
            <div className="text-sm font-normal text-gray-700">
              {dateFormat(Number(vmData.reportDetailData?.reportDate), 'datetime')}
            </div>
          </div>
        </div>
        <div className="mt-10 flex flex-col gap-6 rounded-[10px] bg-white p-6">
          {reportData?.bankResult && (
            <div className="rounded-t-[10px] bg-shareBackground ">
              <div className="flex items-center justify-center gap-4 border-b border-gray-300 py-5">
                <Bank size={36} color="#0052FF" />
                <div className="flex flex-col gap-1">
                  <div className="font-semiBold text-lg text-black">
                    Total Asset: ${handleAmount(reportData?.bankResult.totalValue)}
                  </div>
                  <div className="font-medium text-md ">Bank Accounts Balance</div>
                </div>
              </div>
              <Table
                columns={[
                  {
                    title: 'Account',
                    dataIndex: 'accountId'
                  },
                  {
                    title: 'Account Type',
                    dataIndex: 'subtype'
                  },
                  {
                    title: 'Available Balance',
                    dataIndex: 'availableBalance'
                  }
                ]}
                dataSource={reportData.bankResult.assets as any}
              />
            </div>
          )}
          {reportData?.onchainResult && (
            <div className=" rounded-t-[10px] bg-shareBackground">
              <div className="flex items-center justify-center gap-4 border-b border-gray-300 py-5">
                <CoinsStacked03 size={36} color="#0052FF" />
                <div className="flex flex-col gap-1">
                  <div className="font-semiBold text-lg text-black">
                    Total Asset: ${handleAmount(reportData?.onchainResult.totalValue)}
                  </div>
                  <div className="font-medium text-md ">Onchain Assets</div>
                </div>
              </div>
              <Table
                columns={[
                  {
                    title: 'Network',
                    dataIndex: 'network'
                  },
                  {
                    title: 'Address',
                    dataIndex: 'address'
                  },
                  {
                    title: 'Balance',
                    dataIndex: 'balance',
                    render: (data) => {
                      return handleAmount(data.balance, 4);
                    }
                  },
                  {
                    title: 'Token',
                    dataIndex: 'token'
                  },
                  {
                    title: 'Value',
                    dataIndex: 'value',
                    render: (data) => {
                      return '$' + handleAmount(data.value);
                    }
                  }
                ]}
                dataSource={reportData?.onchainResult.assets as any}
              />
            </div>
          )}
        </div>
        <div className="mt-12 flex justify-center gap-4">
          <Button
            className="w-[250px]"
            variant="outline"
            size="lg"
            onClick={() => {
              navagate('/dashboard?view=report');
            }}
          >
            Back to Dashboard
          </Button>
          <Button
            className="w-[250px]"
            size="lg"
            onClick={() => {
              if (vmData.reportDetailData?.attestationId) {
                navagate(`/preview/${id}`);
              } else {
                vm.setData({ attestReportModalOpen: true });
              }
            }}
          >
            {vmData.reportDetailData?.attestationId ? 'Download' : 'Attest and Download'}
          </Button>
        </div>
      </div>
      <EditReportNameModal
        name={vmData.reportDetailData?.name}
        open={vmData.editReportNameModalOpen}
        onOpenChange={(open: any) => {
          vm.setData({ editReportNameModalOpen: open });
        }}
        onConfirm={(name: any) => {
          vm.editReportName(name);
        }}
      />
      <AttestReportModal
        totalAssets={handleAmount(reportData.totalValue || 0)}
        open={vmData.attestReportModalOpen}
        address={accout?.address || ''}
        onOpenChange={(open) => {
          vm.setData({ attestReportModalOpen: open });
        }}
        onConfirm={(password) => {
          vm.attestReport(id!, password);
        }}
      />
    </div>
  );
}
