import { useCurrentUser } from '@/components/UserProvider';
import { disconnectWallet } from '@/services/proof';
import { OnChainBalanceItem } from '@/types/proof';
import { getChainInfo } from '@/utils/common';
import { DotsGrid, LogIn04, Trash01 } from '@ethsign/icons';
import { Button, Modal, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@ethsign/ui';
import cls from 'classnames';
import React, { useMemo, useState } from 'react';
import { showResultModal } from '../../../../components/base/ResultModal';
import { Events, eventBus } from '../../PageCtrl';

export interface OnchainAssetCardProps {
  wallet: OnChainBalanceItem;
  onDisconnect?: () => void;
}

const TOKEN_ICONS: Record<string, string> = {
  ETH: 'https://sign-public-cdn.s3.us-east-1.amazonaws.com/PoF/token-eth_240724080307.svg',
  USDT: 'https://sign-public-cdn.s3.us-east-1.amazonaws.com/PoF/token-usdt_240724080307.svg',
  USDC: 'https://sign-public-cdn.s3.us-east-1.amazonaws.com/PoF/token-usdc_240724080307.svg'
};

export const TokenInfo: React.FC<{
  icon: React.ReactNode | string;
  balance: string;
  value: string;
  className?: string;
  name?: string;
  onClick?: () => void;
}> = (props) => {
  const { balance, icon, name, value, onClick, className } = props;

  return (
    <div className={cls('flex min-w-[200px] items-center gap-x-4', className)} onClick={onClick}>
      <div className="size-8 overflow-hidden rounded-full">
        {typeof icon === 'string' ? <img crossOrigin="anonymous" className="size-full" src={icon} alt={name} /> : icon}
      </div>

      <div className="">
        <div className="text-[19px] leading-7 text-black">
          {Number(balance).toFixed(4)} {name}
        </div>
        <div className="text-[15px] leading-5 text-gray-500">${Number(value).toFixed(2)}</div>
      </div>
    </div>
  );
};

const DisconnectModal: React.FC<{
  open: boolean;
  address: string;
  onOpenChange: (visible: boolean) => void;
  onSuccess?: () => void;
}> = (props) => {
  const { onOpenChange, open, address, onSuccess } = props;

  const [loading, setLoading] = useState(false);

  const disconnect = async () => {
    try {
      setLoading(true);

      await disconnectWallet(address);

      onSuccess?.();
      setLoading(false);
      onOpenChange(false);

      showResultModal({
        type: 'success',
        title: 'Success',
        content: `Wallet Address [${address}] has been successfully disconnected.`,
        actionText: 'OK',
        onAction({ hide }) {
          eventBus.emit(Events.ONCHAIN_ASSET_DISCONNECT);
          hide();
        }
      });
    } catch (error: any) {
      showResultModal({
        type: 'failed',
        title: 'Failed to disconnect',
        content: error.message,
        actionText: 'Retry',
        onAction: async ({ hide }) => {
          hide();
          disconnect();
        }
      });
    }
  };

  return (
    <Modal
      className="!w-[400px] !gap-y-0"
      open={open}
      footer={false}
      onOpenChange={(visible) => {
        onOpenChange?.(visible);
      }}
      maskClosable={!loading}
      hiddenCloseIcon={loading}
    >
      <div className="flex items-center justify-start">
        <div className="flex size-16 items-center justify-center rounded-full border-[10px] border-[#F8FAFF] bg-[#ECF2FF]">
          <Trash01 color="hsl(var(--primary))" size={32} />
        </div>
      </div>
      <div className="mt-4">
        <strong className="text-[19px] leading-7">Disconnect Wallet Address</strong>
        <p className="mt-2 text-gray-500">Please confirm the wallet information</p>
      </div>
      <div className="mt-4 text-[15px] font-normal leading-5">
        <p className="text-gray-500 ">Address:</p>
        <p className="break-all font-normal text-gray-700">{address}</p>
      </div>

      <div className="flex items-center justify-end gap-x-3">
        <Button
          disabled={loading}
          variant="outline"
          className="mt-6 text-primary"
          onClick={() => {
            onOpenChange?.(false);
          }}
        >
          Cancel
        </Button>
        <Button loading={loading} variant="primary" className="mt-6" onClick={disconnect}>
          Disconnect
        </Button>
      </div>
    </Modal>
  );
};

export const OnchainAssetCard: React.FC<OnchainAssetCardProps> = (props) => {
  const { wallet, onDisconnect } = props;

  const [viewType, setViewType] = useState<'simple' | 'table'>('simple');

  const [disconnectModalVisible, setDisconnectModalVisible] = useState(false);

  const { user } = useCurrentUser();

  const hasTableView = useMemo(() => {
    return wallet.tokens.length > 4;
  }, [wallet.tokens]);

  const totalAmount = wallet.tokens.reduce((acc, cur) => {
    return acc + Number(cur.value);
  }, 0);

  const chainInfo = getChainInfo('1');

  return (
    <div className="rounded-2xl bg-white shadow-[0px_4px_20px_0px_rgba(15,34,212,0.12)]">
      <div className="relative flex items-center justify-between px-6 py-5">
        <div className="flex items-center gap-5">
          <div className="size-[38px] overflow-hidden rounded-full bg-primary-100">
            <img crossOrigin="anonymous" className="block size-full" src={chainInfo?.icon} />
          </div>

          <div className="space-y-[5px]">
            <h3 className="m-0 flex items-center justify-start text-[21px] font-semibold leading-[30px]">
              <span>Total Assets: ${totalAmount?.toFixed(2)}</span>
            </h3>

            <div className="text-[15px] font-semibold leading-5 text-black/70">Wallet Address: {wallet.address}</div>

            <div className="text-[15px] font-semibold leading-5 text-black/70">Network: Ethereum</div>
          </div>
        </div>

        {user?.userId !== wallet.address && (
          <div className="">
            <Button
              variant="outline"
              className="border-gray-200"
              onClick={(e) => {
                e.stopPropagation();
                setDisconnectModalVisible(true);
              }}
            >
              <LogIn04 className="mr-2" size={20} />
              <span>Disconnect</span>
            </Button>
          </div>
        )}
      </div>

      {wallet.tokens?.length > 0 &&
        (viewType === 'simple' ? (
          <div className="flex gap-4 px-6 pb-5">
            {wallet.tokens.slice(0, 4).map((token, index) => (
              <TokenInfo
                key={index}
                balance={token.balance}
                icon={TOKEN_ICONS[token.token]}
                value={token.value}
                name={token.token}
              />
            ))}

            {hasTableView && (
              <TokenInfo
                className="cursor-pointer"
                value="Coin Types"
                balance={`+${wallet.tokens.length - 4} More`}
                icon={<DotsGrid color="black" size={33} />}
                onClick={() => {
                  if (!hasTableView) return;

                  setViewType((old) => (old === 'simple' ? 'table' : 'simple'));
                }}
              />
            )}
          </div>
        ) : (
          <div className="" onClick={(e) => e.stopPropagation()}>
            <hr className="border-gray-300" />

            <Table>
              <TableHeader className="text-[13px] leading-[18px] [&>th]:!text-black">
                <TableHead>Token Ticker</TableHead>
                <TableHead>Balance</TableHead>
                <TableHead>Value</TableHead>
              </TableHeader>

              <TableBody>
                {wallet.tokens.map((token, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <div className="flex w-min items-center justify-start">
                        <div className="size-6 overflow-hidden rounded-full">
                          <img className="block size-full" src={TOKEN_ICONS[token.token]} alt={token.token} />
                        </div>
                        <span> {token.token}</span>
                      </div>
                    </TableCell>
                    <TableCell>{token.value}</TableCell>
                    <TableCell>{token.balance}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ))}

      <DisconnectModal
        address={wallet.address}
        open={disconnectModalVisible}
        onOpenChange={(visible) => setDisconnectModalVisible(visible)}
        onSuccess={() => {
          onDisconnect?.();
        }}
      />
    </div>
  );
};
